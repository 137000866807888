export function showMenuAccordingType(url: string) {
  const IMG_FILE_TYPES = ['.jpeg', '.jpg', '.png', '.webp']

  return IMG_FILE_TYPES.some(extension => url.includes(extension))
}

export function showMenuAccordingStatus(status: string) {
  const STATUS_DISABLED_DOWNLOAD = ['awaiting_analysis', 'approved']

  return STATUS_DISABLED_DOWNLOAD.includes(status) ? ['download'] : ['download', 'delete']
}
