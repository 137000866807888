import type { OptionsForm } from '~/utils/installation/Installation'

export function getHardwareListLabels(item: OptionsForm, typeUnit: string): string {
  const unitOfMeasurement = {
    modules: 'Wp', // watt-pico
    inverters: 'kW', // kilo-watt
    microinverters: 'kW', // kilo-watt
  }

  if (item.power && item.brand)
    return `${item.brand} - ${item.name} - ${item.power}${unitOfMeasurement[typeUnit]}`

  if (item.power)
    return `${item.name} - ${item.power}${unitOfMeasurement[typeUnit]}`

  return `${item.name}`
}
