<script setup lang="ts">
import accountBalanceIcon from '~icons/material-symbols/account-balance-outline'
import lockIcon from '~icons/material-symbols/lock-outline'
import type { ReceiptModel } from '~/utils/receipt-model/ReceiptModel'

const props = defineProps<{
  data: ReceiptModel
  combo: boolean
  isOnboardingFlex: boolean
}>()

const { t } = useI18n()
const { formatMoney } = useMoney()
const hasDataValue = Object.keys(props.data).length
const hasTransfers = hasDataValue ? props.data.transfers.length > 0 : 0
const PERCENT_IN_CASH = 100
const PERCENT_INSTALLMENT = 50
const isInstallment = props.data.issuance_model === 'installment'
const partnerTransfers = props.data.transfers ? props.data.transfers.filter(transfer => transfer.category === 'financing') : []

function setClassTransfer(transfer) {
  return (transfer.status === 'waiting_send_payment' && transfer.kind === 'after_validation_installation')
}
</script>

<template>
  <div v-if="hasDataValue" class="transfer_summary">
    <div class="title">
      <span class="text-[12px] md:system:text-[20px]">
        {{ t('customer.transfer_summary') }}
      </span>
    </div>
    <div>
      <div class="flex justify-between my-nano">
        <span class="fonts-body-small-regular md:system:fonts-body-medium-regular text-neutral-low-medium">
          {{ t('customer.financed_value') }}:
        </span>
        <span class="fonts-body-small-regular  md:system:fonts-body-medium-regular text-brand-primary-dark">
          {{ formatMoney(props.data.financing_value) }}
        </span>
      </div>
      <div class="ml-micro md:sm:system:ml-4xs grid grid-cols-1 gap-y-3">
        <div class="flex justify-between">
          <template v-if="props.combo">
            <span class="fonts-body-small-regular md:system:fonts-body-medium-regular break-words text-neutral-low-medium">
              {{ hasTransfers ? t('customer.kit_value') : t('customer.waiting_kit_value') }}:
            </span>
          </template>
          <template v-else>
            <span class="fonts-body-small-regular md:system:fonts-body-medium-regular break-words text-neutral-low-medium">
              {{ t('customer.equipment_value') }}:
            </span>
          </template>
          <span class="fonts-body-small-regular text-right md:system:fonts-body-medium-regular break-words text-neutral-low-light font-normal">
            {{ hasTransfers ? formatMoney(props.data.equipment_value) : t('customer.opened') }}
          </span>
        </div>
        <template v-if="hasTransfers">
          <div
            v-for="(transfer, indexTransfer) in partnerTransfers"
            :key="indexTransfer"
            class="flex justify-between"
          >
            <span class="fonts-body-small-regular md:system:fonts-body-medium-regular break-words text-neutral-low-medium">
              {{ t(`customer.transfer_${transfer.kind}`) }} ({{ transfer.percent }}%):
            </span>
            <span
              class="fonts-body-small-regular md:system:fonts-body-medium-regular text-right  flex gap-1 items-center  justify-end"
              :class="` ${setClassTransfer(transfer) ? 'text-neutral-low-light font-normal' : 'text-brand-primary-dark'}`"
            >
              <lockIcon v-if="setClassTransfer(transfer)" />
              {{ formatMoney(transfer.value) }}
            </span>
          </div>
        </template>
        <template v-else>
          <div class="flex justify-between">
            <span v-if="props.combo" class="fonts-body-small-regular md:system:fonts-body-medium-regular break-words text-neutral-low-medium">
              {{ t('customer.transfer_after_contract_approval') }}  ({{ props.data.issuance_model === 'in_cash' ? PERCENT_IN_CASH : PERCENT_INSTALLMENT }}%):
            </span>
            <span
              class="fonts-body-small-regular  md:system:fonts-body-medium-regular text-brand-primary-dark"
            > {{ t('customer.opened') }}
            </span>
          </div>
          <div v-if="isInstallment" class="flex justify-between">
            <span class="fonts-body-small-regular md:system:fonts-body-medium-regular break-words text-neutral-low-medium">
              {{ t('customer.transfer_after_validation_installation') }} (50%):
            </span>
            <span
              class="fonts-body-small-regular  md:system:fonts-body-medium-regular text-right  flex gap-1 items-center text-neutral-low-light font-normal"
            >
              <lockIcon />
              {{ t('customer.opened') }}
            </span>
          </div>
        </template>
      </div>

      <template v-if="props?.data?.roles?.invoices?.commission">
        <SolDivider v-if="props.data.commissions.financing.value > 0" thickness="x-small" orientation="horizontal" class="mt-nano mb-quark" />
        <div v-if="props.data.commissions.financing.value > 0" class="grid grid-cols-2">
          <span class="fonts-body-small-regular md:system:fonts-body-medium-regular text-neutral-low-medium">
            {{ t('customer.financing_commission') }}:
          </span>
          <span class="text-brand-primary-dark fonts-body-small-regular text-right">{{ formatMoney(props.data.commissions.financing.value) }}</span>
        </div>
        <SolDivider thickness="x-small" orientation="horizontal" class="mt-nano mb-nano" />
        <div v-if="props.data?.commissions?.insurance" class="grid grid-cols-2">
          <span class="fonts-body-small-regular md:system:fonts-body-medium-regular text-neutral-low-medium">{{
            t('customer.insurance_commission') }}:</span>
          <span class="text-brand-primary-dark fonts-body-small-regular text-right">{{ formatMoney(props.data.commissions.insurance.value) }}</span>
        </div>
      </template>
    </div>

    <div class="md:system:flex justify-between items-start md:site:items-center mt-2xs my-quark md:system:my-4xs">
      <div class="flex items-start md:site:items-center justify-between w-full flex-col md:site:flex-row">
        <div class="flex items-center md:site:gap-1">
          <div>
            <accountBalanceIcon class="hidden md:site:flex md:site:icon-size-medium" />
          </div>
          <div class="ml-quark">
            <div class="flex align-middle flex-col">
              <div class="flex">
                <accountBalanceIcon class="icon-size-medium pr-quark md:site:flex md:site:hidden" />
                <span class="fonts-body-small-bold md:system:fonts-body-large-bold text-brand-primary-dark">
                  {{ t('customer.total_transfer_to_registered_account') }}
                </span>
              </div>
              <template v-if="props.isOnboardingFlex">
                <template v-if="props.combo">
                  <span class="fonts-body-small-regular md:system:fonts-body-medium-regular">
                    {{ t('customer.after_installation_value_transfer') }}
                  </span>
                </template>
                <template v-else>
                  <span v-if="props?.data?.roles?.invoices?.commission">
                    {{ t('customer.commission_transfer_after_nf_approval') }}
                  </span>
                  <span v-else>
                    {{ t('customer.value_transfer_after_validation_installation') }}
                  </span>
                </template>
              </template>
              <template v-else>
                <span v-if="props.data.issuance_model === 'in_cash'" class="fonts-body-small-regular md:system:fonts-body-medium-regular">
                  {{ t('customer.after_contract_approval_value_will_be_transferred') }}
                </span>
                <span v-if="isInstallment" class="fonts-body-small-regular md:system:fonts-body-medium-regular">
                  {{ `${t('customer.after_contract_approval_value_will_be_transferred')} O valor bloqueado será transferido após a validação da instalação.` }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="mt-quark md:site:ml-8 md:site:ml-0 min-w-[150px] md:site:text-right flex flex-col gap-2">
          <span class="fonts-body-small-bold text-brand-primary-dark !text-[16px]">
            {{ hasTransfers ? (props.data.transfers_paid > 0 ? formatMoney(props.data.transfers_paid) : '') : t('customer.opened') }}
          </span>
          <template v-if="isInstallment">
            <div
              v-if="props.data.transfers.find(transfer => transfer.status === 'waiting_send_payment') || !hasTransfers"
              class="flex gap-1 align-bottom  md:site:justify-end align-middle"
            >
              <lockIcon />
              <span class="fonts-body-small-regular md:system:fonts-body-large-regular  text-neutral-low-light font-normal">
                {{ hasTransfers ? formatMoney(props.data.transfers_pending) : t('customer.opened') }}
              </span>
            </div>
            <div
              v-else
              class="flex gap-1 md:site:justify-end"
            >
              <span class="text-brand-primary-dark !text-[16px] block">
                {{ formatMoney(props.data.transfers_pending) }}
              </span>
            </div>
          </template>
          <template v-if="props.isOnboardingFlex">
            <div class="flex gap-1 align-bottom md:site:justify-end">
              <lockIcon />
              <span class="!text-[16px] block text-neutral-low-light font-normal">
                {{ hasTransfers ? formatMoney(props.data.transfers_pending) : t('customer.opened') }}
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.transfer_summary {
  @apply my-micro p-micro;
  @apply bg-neutral-high-light rounded-sm;

  @screen md:system {
    @apply my-xs p-xs;
  }

}

.receipt-model .title span {
  @apply font-highlight  text-neutral-low-pure;
}

.method_receipt .subtitle {
  @apply leading-3 mt-quark mb-2xs;
}
</style>
