<script setup lang="ts">
import { useAddonStore } from '~/store/addons'
import type { Addons } from '~/utils/simulation/Simulation'

const props = defineProps<{
  projectIsCompleted?: boolean
  refetchInsurance: (value: any) => void
  canSelectAmpera?: boolean
}>()

const { t } = useI18n()

const { toggleAmpera, addons_list } = useAddonStore()

const { formatMoney } = useMoney()

function toggleAddonAdapter(value: any) {
  toggleAmpera(value)
  props.refetchInsurance(value)
}

function getLabel(addonName: string) {
  const addon = addons_list.find(value => value.product_slug === addonName)
  if (addon?.applied)
    return 'Adicionado'

  return 'Adicionar'
}

function getIsApplied(addonName: string) {
  const addon = addons_list.find(value => value.product_slug === addonName)
  return addon?.applied
}

function getFieldValueOfAddon(addon: string, field: keyof Addons) {
  const data = addons_list.find(value => value.product_slug === addon)

  if (data)
    return Math.abs(Number(data[field]))

  return null
}

// Modal
const modal = ref(false)
function openModal() {
  modal.value = true
}
function closeModal() {
  modal.value = false
}
// Close Modal
</script>

<template>
  <div class="col-span-5" :class="projectIsCompleted ? 'cursor-not-allowed' : ''">
    <div class="details-container">
      <h2 class="text-2xs font-bold lg:site:mb-8">
        Monitoramento e descontos!
      </h2>

      <div v-if="addons_list.find(item => item.product_slug === 'Ampera')" class="container-benefits-details">
        <div class="benefits-details-title ">
          <img class="benefits-tag" src="/icons/ampera.svg">
          <div class="mb:site:w-[240px]">
            <h3 class="fonts-body-large-bold benefits-title !text-3xs">
              Ampera
            </h3>
            <p v-if="canSelectAmpera" class="fonts-body-medium-regular text-brand-primary-medium">
              {{ t('simulation.ampera.description') }} <a class="underline fonts-body-medium-regular text-brand-secondary-pure cursor-pointer" @click="openModal">{{ t('simulation.ampera.textLink') }}</a>
            </p>
            <div v-else>
              <p class="fonts-body-medium-regular text-brand-primary-medium mb-1">
                {{ t('simulation.ampera.lack_of_stock.text1') }}
              </p>
              <p class="fonts-body-medium-regular text-brand-primary-medium">
                {{ t('simulation.ampera.lack_of_stock.text2') }}
              </p>
            </div>
          </div>
        </div>
        <div class="benefits-details-elements">
          <div class="min-w-[142px] flex flex-col gap-1">
            <p class="!text-[12px] text-neutral-low-light font-bold">
              Desconto de:
            </p>

            <h3 class="text-[16px] benefits-title text-neutral-low-medium">
              <strong class="!text-[16px] fonts-body-medium-bold">{{ formatMoney(getFieldValueOfAddon('Ampera', 'installment_price')) }}</strong><span class="text-[14px]">/mês</span>
            </h3>
          </div>
          <SolSwitch
            id="ampera"
            :label="getLabel('Ampera')"
            text-direction="right"
            :disabled="projectIsCompleted || !props.canSelectAmpera"
            :checked="getIsApplied('Ampera')"
            name="Ampera"
            value="Ampera"
            @change="toggleAddonAdapter"
          />
        </div>

        <SolModal
          id="video-modal"
          title="Conheça o Ampera"
          :is-open="modal"
          action-primary-text="Fechar"
          @close="closeModal"
          @action:primary="closeModal"
        >
          <div>
            <iframe
              class="w-full"
              height="346"
              src="https://www.youtube.com/embed/iBFq3a5EuYA?si=Rks3NkiPAjI9hbKk"
              title="Manual de instalação do Ampera"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />

            <div class="flex items-center mt-4 gap-2 md:site:gap-1">
              <img src="/icons/instance.svg" alt="Icone informativo">
              <span class="text-micro font-normal">Uma vez solicitado o Ampera, a instalação deverá ocorrer nos termos do Acordo Operacional.</span>
            </div>
          </div>
        </SolModal>
      </div>
      <div v-else class="empty-ampera">
        <img src="/images/not-found.png" class="not-found mb-4xs">
        <h1 class="text-brand-primary-dark fonts-heading-h3 mb-4xs">
          Não foi possível exibir os dados
        </h1>
        <p class="fonts-body-x-large-regular text-brand-primary-medium mb-4xs">
          No momento estamos com instabilidade no sistema. Por favor tente novamente em alguns minutos.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.empty-ampera{
  @apply flex flex-col justify-center text-center items-center;
}
.details-container {
  @apply flex justify-between w-full flex-col gap-6 lg:site:gap-0;
  @apply p-4xs my-6 bg-neutral-high-pure rounded-lg mb-giga;
  @screen lg:site {
    @apply p-xs;
  }

  > .container-benefits-details {
    @apply w-full my-0 flex flex-col;
    @screen lg:site {
      @apply w-full my-0 flex flex-row gap-6;
    }
    .benefits-details-title {
      @apply flex w-full mb-2xs;
      @screen lg:site {
        @apply flex w-full;
      }

      .benefits-title {
        @apply mb-nano text-stroke-brand-primary-dark;
      }

      > .benefits-tag {
        @apply text-md mr-nano mb-nano self-start max-w-[24px];
        @screen lg:site {
          @apply mx-nano;
        }
      }
    }
    .benefits-details-elements {
      @apply flex w-full self-center flex-col mt-0 gap-1 flex-row pl-8;
      @screen lg:site {
        @apply flex ml-nano text-right w-auto px-0 gap-6 lg:site:min-w-[292px];
      }
      .benefits-title {
        h3 {
          @apply ml-xs;
          @screen lg:site {
            @apply ml-0;
          }
        }
      }
      >p {
        @apply ml-xs;
        @screen lg:site {
          @apply ml-0;
        }
      }
      .sol-switch-core {
        @apply justify-end;
        @screen lg:site {
          @apply ml-0;
        }
        .label {
          @apply min-w-[70px] text-left;
        }
      }

    }
  }
}
</style>
