import type { RestConnections } from '~/types'

export default {
  dev: {
    url: 'https://kongzinho.solfacil.com.br/stg-core/v1/',
  },
  prod: {
    url: 'https://kong.solfacil.com.br/prd-core/v1/',
  },
  stg: {
    url: 'https://kongzinho.solfacil.com.br/stg-core/v1/',
  },
} as RestConnections
