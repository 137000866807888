<template>
  <main class="main-content">
    <RouterView />
    <NavigationTopbar :initial="10" />
    <NavigationNavbar :initial="10" />
  </main>
</template>

<style lang="scss" scoped>
.main-content {
  @apply px-4 py-10 text-center text-brand-primary-pure dark:text-highlight-light;
}
</style>
