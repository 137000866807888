<script setup lang="ts">
import { useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import IconLock from '~icons/material-symbols/lock'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'
import { showMenuAccordingType } from '~/composables/uploadFile'
import CustomerRegisterService from '~/services/customer-register/CustomerRegister'
import { useFinancing } from '~/store/useFinancing'
import { Formalization } from '~/types/enum'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import type { Document, DocumentModel, DocumentStatusEnum, StageFormalization, TypesErrorBill } from '~/utils/customer-register/CustomerRegister'
import download from '~/utils/download'
import type { FormalizationStatus } from '~/utils/financing-flow/Financing'

const props = defineProps<{
  position: number
  stage: StageFormalization
  stageReproved: string
  statusProject: FormalizationStatus
}>()

const emit
  = defineEmits<{
    (e: 'errorDocumentBill', v: TypesErrorBill): void
    (e: 'clientStatusSteps', v: FormalizationStatus['documentation']): void
  }>()
const { createErrorToast } = useToast()
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'application/pdf']

const { track } = useMixpanel()

const fieldIsDisabledBasedStatus = ref(false)
const customerService = new CustomerRegisterService(useApi('customerRegister'))
const blocked = ref(true)

const router = useRouter()
const id = router.currentRoute.value.params.id as string
const { financing } = useFinancing(String(id))
const disableStepsAccordingStatus = ['canceled', 'expired'].includes(financing?.status ?? '')
const EnumTags: DocumentStatusEnum = {
  pending: {
    id: 'clientDataPending',
    text: 'Em andamento',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  pendency: {
    id: 'clientDataPendency',
    text: 'Pendenciado',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  under_analysis: {
    id: 'clientDataUnderAnalysis',
    text: 'Em análise',
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  reproved: {
    id: 'clientDataReproved',
    text: 'Reprovado',
    variant: 'fill',
    size: 'medium',
    type: 'negative',
  },
  approved: {
    id: 'clientDataApproved',
    text: 'Aprovado',
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
}

const errorInstallation = ref({ documentMatchesBill: '', addressMatchesBill: '' })
const errorIdentityMatchesDocument = ref('')

onMounted(async () => {
  checkStageReproved()
  await getDocumentData()
})

const loadingDocument = ref(false)
const status = ref('' as FormalizationStatus['documentation'])
const responseDocument = ref({} as DocumentModel)
const documentUrls = ref({
  identity: ref<string[]>([]),
  energy_bill: ref<string[]>([]),
})

const tag = computed(() => EnumTags[props.statusProject.documentation])

const { t } = useI18n()

const initialValues = {
  uploadIdentifier: null,
  uploadElectricityBill: null,
}

const validationSchema = computed(() => toTypedSchema(
  zod.object({
    uploadIdentifier: zod
      .any()
      .refine(uploadIdentifier => uploadIdentifier?.length || responseDocument.value.identity_matches_document, t('form.file_required')),
    uploadElectricityBill: zod
      .any()
      .refine(uploadElectricityBill => uploadElectricityBill?.length || responseDocument.value.installation_address_matches_bill, t('form.file_required')),

  }),
))

const { handleSubmit, validate, setFieldError } = useForm({
  validationSchema,
  initialValues,
})

function setFieldErrorNoMatchElectricityBill() {
  if (errorInstallation.value.documentMatchesBill)
    setFieldError('uploadElectricityBill', errorInstallation.value.documentMatchesBill)

  if (errorInstallation.value.addressMatchesBill)
    setFieldError('uploadElectricityBill', errorInstallation.value.addressMatchesBill)

  emit('errorDocumentBill', errorInstallation.value)
}

function setFieldErrorNoMatchIdentity() {
  setFieldError('uploadIdentifier', errorIdentityMatchesDocument.value)
}

onUpdated(async () => {
  if (errorInstallation.value.addressMatchesBill !== '' || errorInstallation.value.documentMatchesBill !== '')
    setFieldErrorNoMatchElectricityBill()

  if (errorIdentityMatchesDocument.value !== '')
    setFieldErrorNoMatchIdentity()

  updateStatusWithFlow()
})

function updateStatusWithFlow() {
  status.value = props.statusProject.documentation
}

async function getDocumentData() {
  try {
    loadingDocument.value = true

    const response = await customerService.get_documents(id)
    if (response) {
      responseDocument.value = response
      const { documents } = response
      status.value = props.statusProject.documentation
      const isApproved = status.value === 'approved'

      documents.forEach((document) => {
        const isIdentityDocument = document.type === 'identity'
        const isEnergyBillDocument = document.type === 'energy_bill'

        if ((isIdentityDocument && responseDocument.value.identity_matches_document) || (isIdentityDocument && isApproved)) {
          if (!documentUrls.value.identity.includes(document.url))
            documentUrls.value.identity.push(document.url)
        }
        else if ((isEnergyBillDocument && responseDocument.value.installation_address_matches_bill && responseDocument.value.installation_document_matches_bill) || (isEnergyBillDocument && isApproved)) {
          if (!documentUrls.value.energy_bill.includes(document.url))
            documentUrls.value.energy_bill.push(document.url)
        }
      })

      emit('clientStatusSteps', status.value)
      fieldIsDisabledBasedStatus.value = status.value === 'approved' || status.value === 'reproved' || status.value === 'under_analysis'

      if (status.value === 'pendency') {
        if (!responseDocument.value.installation_address_matches_bill && !responseDocument.value.installation_document_matches_bill) {
          errorInstallation.value.documentMatchesBill = t('formDocument.errorMessageInstallationDocumentMatchesBill')
          errorInstallation.value.addressMatchesBill = t('formDocument.errorMessageInstallationAddressAndDocumentMatchesBill')
        }
        else if (!responseDocument.value.installation_address_matches_bill) {
          errorInstallation.value.addressMatchesBill = t('formDocument.errorMessageInstallationAddressMatchesBill')
        }
        else if (!responseDocument.value.installation_document_matches_bill) {
          errorInstallation.value.documentMatchesBill = t('formDocument.errorMessageInstallationDocumentMatchesBill')
        }
      }
      else {
        errorInstallation.value.documentMatchesBill = ''
        errorInstallation.value.addressMatchesBill = ''
      }

      if (status.value === 'pendency' && !responseDocument.value.identity_matches_document)
        errorIdentityMatchesDocument.value = t('formDocument.errorMessageIdentityMatchesDocument')
      else
        errorIdentityMatchesDocument.value = ''
    }
  }
  catch {
    loadingDocument.value = false
  }
  finally {
    loadingDocument.value = false
  }
}

const loadingUpload = ref(false)

async function uploadFile(uploadIdentifier: File[], uploadElectricityBill: File[]) {
  const uploadPromises: Promise<ResponseModel<Document>>[] = []

  if (uploadIdentifier) {
    uploadIdentifier.forEach((file: File) => {
      const formDataIdentity = new FormData()
      formDataIdentity.append('document', file, file.name)
      uploadPromises.push(customerService.upload_documents(id, 'identity', formDataIdentity))
    })
  }

  if (uploadElectricityBill) {
    uploadElectricityBill.forEach((file: File) => {
      const formDataEnergyBill = new FormData()
      formDataEnergyBill.append('document', file, file.name)
      uploadPromises.push(customerService.upload_documents(id, 'energy_bill', formDataEnergyBill))
    })
  }

  await Promise.all(uploadPromises)
}

const saveDocument = handleSubmit(async (event: any) => {
  loadingUpload.value = true

  const { valid } = await validate()
  if (valid) {
    try {
      await uploadFile(event.uploadIdentifier, event.uploadElectricityBill)
      await getDocumentData()

      if (status.value === 'pendency' && !responseDocument.value.installation_address_matches_bill && !responseDocument.value.installation_document_matches_bill)
        setFieldErrorNoMatchElectricityBill()

      track('formalizing_documentation_button_next', { trigger: 'Clique no botão enviar em Documentação PF' })

      fieldIsDisabledBasedStatus.value = true
      loadingUpload.value = false
    }
    catch {
      loadingUpload.value = false
      createErrorToast(t('formDocument.responseError'))
    }
    finally {
      loadingUpload.value = false
    }
  }
})

const disableButtonSendDocument = computed(() => disableStepsAccordingStatus || fieldIsDisabledBasedStatus.value)

const showAlertReason = computed(() => responseDocument.value.reason && (status.value === 'pending' || status.value === 'pendency'))

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.DOCUMENTATION
}
const disabledUploadField = computed(() => disableStepsAccordingStatus || loadingUpload.value)

function clearDocumentUrls() {
  documentUrls.value.identity = []
  documentUrls.value.energy_bill = []
}

function updateAfterAnalysisCanceled() {
  clearDocumentUrls()
  fieldIsDisabledBasedStatus.value = false
  status.value = 'pending'
}

const showModalToPreviewFile = ref(false)
const imgUrl = ref('')

function previewFile(url: string) {
  imgUrl.value = url
  showModalToPreviewFile.value = true
}
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.DOCUMENTATION}`"
    :title="t('customer.document_data_title')"
    :position="String(position)"
    :status="props.statusProject.documentation"
    :tag="blocked ? undefined : tag"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.DOCUMENTATION"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>
    <template v-if="!loadingDocument">
      <div class="mt-2">
        <p>{{ t('customer.document_data_subtitle') }}</p>
      </div>
      <div class="form-client-data !mb-0">
        <SolAlert
          v-if="showAlertReason"
          id="informative-feedback-receipt-model"
          class="my-3"
          :title="t('formDocument.alertError')"
          feedback="error"
        >
          {{ responseDocument.reason }}
        </SolAlert>

        <form>
          <SolFileUpload
            v-if="!documentUrls.identity.length"
            id="uploadIdentifier"
            name="uploadIdentifier"
            class="mt-8"
            :class="{ 'pointer-events-none opacity-60': disabledUploadField }"
            :use-field="useField"
            :accept="ACCEPTED_FILE_TYPES.join(',')"
            :multiple="true"
            :placeholder="t('formDocument.placeholder')"
            :label="t('formDocument.labelDocumentSol')"
          />
          <SolFileUpload
            v-for="(url, index) in documentUrls.identity"
            id="uploadIdentifier"
            :key="index"
            name="uploadIdentifier"
            class="pb-4xs"
            :class="[{ 'pointer-events-none opacity-60': disabledUploadField }, { 'mt-8': index === 0 }]"
            :use-field="useField"
            :label="index === 0 ? t('formDocument.labelDocumentSol') : ''"
            :download-src="url"
            :download-menu="showMenuAccordingType(url) ? ['download', 'preview'] : ['download']"
            @menu:download="download.downloadFile(documentUrls.identity[index])"
            @menu:preview="previewFile(url)"
          />

          <SolFileUpload
            v-if="!documentUrls.energy_bill.length"
            id="uploadElectricityBill"
            name="uploadElectricityBill"
            class="mt-8"
            :class="{ 'pointer-events-none opacity-60': disabledUploadField }"
            :use-field="useField"
            :accept="ACCEPTED_FILE_TYPES.join(',')"
            :multiple="true"
            :placeholder="t('formDocument.placeholder')"
            :label="t('formDocument.labelElectricityBill')"
          />
          <SolFileUpload
            v-for="(url, index) in documentUrls.energy_bill"
            id="uploadElectricityBill"
            :key="index"
            name="uploadElectricityBill"
            :class="[{ 'pointer-events-none opacity-60': disabledUploadField }, { 'mt-8': index === 0 }]"
            :use-field="useField"
            :label="index === 0 ? t('formDocument.labelElectricityBill') : ''"
            :download-src="url"
            :download-menu="showMenuAccordingType(url) ? ['download', 'preview'] : ['download']"
            @menu:download="download.downloadFile(documentUrls.energy_bill[index])"
            @menu:preview="previewFile(url)"
          />

          <div class="flex justify-end border-t border-neutral-high-medium pt-6 mt-8 gap-4 flex-col md:system:flex-row">
            <SharedCancelStep
              :status-step="statusProject.documentation"
              step-to-cancel="document_form"
              :disabled-button-by-status="disableStepsAccordingStatus"
              @step-canceled="updateAfterAnalysisCanceled()"
            />
            <SolButton
              id="document_confirm"
              class="w-full md:system:w-auto order-1 md:system:order-2"
              :loading="loadingUpload"
              :disabled="disableButtonSendDocument"
              size="large"
              @click="saveDocument"
            >
              {{ t('app.send') }}
            </SolButton>
          </div>
        </form>
      </div>
    </template>
    <template v-else>
      <div class="container">
        <div class="my-4 card-container flex flex-col justify-between w-full">
          <span class="loader-project" />
        </div>
      </div>
    </template>
  </CustomerElementAccordion>
  <SolModal
    id="modal-preview-file"
    :is-open="showModalToPreviewFile"
    title=""
    :size="{ desktop: 'extra-large', mobile: 'full' }"
    @close="showModalToPreviewFile = false"
  >
    <div class="flex justify-center w-full">
      <img :src="imgUrl" alt="Document img">
    </div>
  </SolModal>
</template>

<style lang="scss" scoped>
.error-message {
  @apply text-feedback-negative-pure py-nano;
  @apply fonts-subtitle-small;
}

.form-client-data {
  @apply mt-sm;
}
</style>
