import type { RestConnections } from '~/types'

export default {
  dev: {
    url: 'https://kongzinho.solfacil.com.br/stg-payment-bff',
  },
  prod: {
    url: 'https://kong.solfacil.com.br/prd-payment-bff',
  },
  stg: {
    url: 'https://kongzinho.solfacil.com.br/stg-payment-bff',
  },
} as RestConnections
