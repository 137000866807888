<script setup lang="ts">
const router = useRouter()

async function fallbackRouter() {
  router.push('/')
}

onMounted(() => {
  fallbackRouter()
})
const { t } = useI18n()
</script>

<template>
  <main p="x4 y10" text="center teal-700 dark:gray-200">
    <div text-4xl>
      <div i-carbon-warning inline-block />
    </div>
    <RouterView />
    <div>
      <SolButton id="back" btn text-sm m="3 t8" @click="router.back()">
        {{ t('buttons.back') }}
      </SolButton>
    </div>
  </main>
</template>
