import axios from 'axios'
import env from '~/env'
import { injectAccessTokenInterceptor, refreshTokenResponseInterceptor } from '../keycloack-api/keycloack-api.interceptors'

const http = axios.create({
  baseURL: env.VITE_CORE_API_URL,
})

// @ts-expect-error: error is not a valid axios response
http.interceptors.request.use(injectAccessTokenInterceptor)
http.interceptors.response.use(null, refreshTokenResponseInterceptor)

export default http
