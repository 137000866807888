export function useMoney() {
  return {
    formatMoney: (value: number | unknown) => {
      if (!value && value !== 0)
        return '--'

      if (typeof value == 'number') {
        const formatter = new Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })
        return formatter.format(value)
      }
      return '--'
    },
  }
}
