<script setup lang="ts">
const { t } = useI18n()
const router = useRouter()

function goToNewPage() {
  router.push('/simulation/new')
}

async function fallbackRouter() {
  router.push('/')
}

function goToListPage() {
  fallbackRouter()
}
</script>

<template>
  <div class="container">
    <section class="banner">
      <img src="/images/search-list.svg" class="inline-block" title="Imagem da lista" alt="Lista">
      <h1 class="fonts-heading-h1">
        Simulação em processamento
      </h1>
      <p>Sua simulação ainda está sendo analisada. Você poderá consultá-la em breve na sua listagem</p>
      <div class="flex gap-2 inline-flex my-xs  md:site:hidden">
        <SolButton
          id="btn-logout-collapse-sidebar"
          variant="secondary"
          size="small"
          @click="goToListPage"
        >
          {{ t('simulation.golist') }}
        </SolButton>
        <SolButton
          id="btn-logout-collapse-sidebar"
          variant="primary"
          size="small"
          @click="goToNewPage"
        >
          {{ t('simulation.new') }}
        </SolButton>
      </div>
      <div class="flex gap-2 my-xs inline-flex show-md">
        <SolButton
          id="btn-logout-collapse-sidebar"
          variant="secondary"
          size="large"
          @click="goToListPage"
        >
          {{ t('simulation.golist') }}
        </SolButton>
        <SolButton
          id="btn-logout-collapse-sidebar"
          variant="primary"
          size="large"
          @click="goToNewPage"
        >
          {{ t('simulation.new') }}
        </SolButton>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.container{

  @apply flex flex-col items-start px-4xs py-6 mt-36 ;
  @screen md:system {
    @apply px-14 py-12;
  }
  @screen sm:system {
   @apply mt-0;
  }

  .show-md{
    @apply hidden;
    @screen md:site {
      @apply inline-flex;
    }
  }
  > .banner{
    @apply text-center bg-neutral-high-pure rounded-lg px-xl py-md w-full;
    @screen md:site {
      @apply px-mega;
    }
  }

}
</style>

<route lang="yaml">
meta:
  layout: simulation
    </route>
