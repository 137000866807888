import { ref } from 'vue'

/**
 * A `composable` function that returns the search params of the current URL and allows to set them.
 *
 * @returns The search params and a function to set them.
 */
export default function useSearchParams() {
  const { pathname, search } = window.location
  const params = ref(new URLSearchParams(search))

  // hear of search params changes
  window.addEventListener('popstate', () => {
    params.value = new URLSearchParams(search)
  })

  /**
   * Set the search params of the current URL.
   *
   * @param newParams - The new search params to set.
   */
  function setParam(newParams: Record<string, string>) {
    const searchParams = new URLSearchParams(search)

    Object.entries(newParams).forEach(([key, value]) => {
      if (value)
        searchParams.set(key, value)
      else
        searchParams.delete(key)
    })

    window.history.replaceState({}, '', `${pathname + (searchParams ? `?${searchParams}` : '')}`)
    params.value = searchParams
  }

  return [params, setParam] as const
}
