<script setup lang="ts">
import type { Project } from '~/utils/project/Project'

const props = defineProps<{
  projectValueChosen: number
  downPaymentChosen: number
  project: Project
  disableFields: boolean
  loadingDetails: boolean
  maxGracePeriod: number
  requestInstallments: (...args: any) => void
  selectDownPayment: (...args: any) => void
  selectProjectValue: (...args: any) => void
  setProjectValueError: (error: boolean) => void
}>()

const downPayment = ref(props.downPaymentChosen)
const downPaymentError = ref('')
const projectValue = ref(props.projectValueChosen)
const projectValueError = ref('')

function projectValueGtValidation(max?: number, min?: number, value?: number): string {
  if (!max || !min || !value)
    return ''

  if (value > max)
    return 'Valor maior que permitido'

  if (value < min)
    return 'Valor menor que permitido'

  if (value < 0)
    return 'O valor do projeto não pode ser negativo'

  return ''
}

function projectFinancingValueGtMax(max?: number, downPayment?: number): string {
  if (!max || !downPayment)
    return ''

  if (downPayment > projectValue.value)
    return 'Entrada deve ser menor que valor do projeto'

  if (projectValue.value - downPayment > max)
    return 'Valor maior que permitido'

  if (downPayment < 0)
    return 'O valor de entrada não pode ser negativo'

  return ''
}

let debounceTimer: any

watch(projectValue, (value) => {
  clearTimeout(debounceTimer)

  projectValueError.value = projectValueGtValidation(
    props.project.max_approved_project_value,
    props.project.min_approved_project_value,
    value,
  )

  hasError()

  debounceTimer = setTimeout(() => {
    if (!projectValueError.value) {
      props.selectProjectValue(value)
      props.requestInstallments()
    }
  }, 1000)
})

watch(downPayment, (value) => {
  clearTimeout(debounceTimer)
  downPaymentError.value = projectFinancingValueGtMax(
    props.project.max_financing_value,
    value,
  )

  hasError()

  debounceTimer = setTimeout(() => {
    if (!downPaymentError.value) {
      props.selectDownPayment(value)
      props.requestInstallments()
    }
  }, 1000)
})

function hasError() {
  projectValueError.value || downPaymentError.value ? props.setProjectValueError(true) : props.setProjectValueError(false)
}
</script>

<template>
  <div class="grid grid-cols-2 gap-4 mt-2xs">
    <InputMoneyInput
      id="just-test-2"
      v-model="projectValue"
      size="medium"
      required
      :error="projectValueError"
      name="projectValue"
      label="Valor do projeto"
      placeholder="Digite o valor do projeto*"
      :disabled="disableFields"
    />
    <InputMoneyInput
      id="just-test-2"
      v-model="downPayment"
      size="medium"
      name="downPayment"
      :error="downPaymentError"
      label="Entrada"
      placeholder="Digite o valor da entrada*"
      :disabled="disableFields"
    />
  </div>
</template>

<style lang="scss" scoped>
.btn-selectable-left{
  @apply border rounded-l-md;
  @apply p-nano cursor-pointer font-bold;
}

.btn-selectable-right{
  @apply border rounded-r-md;
  @apply p-nano cursor-pointer font-bold;
}
.btn-selected{
  @apply bg-brand-secondary-pure border-brand-secondary-pure;

  .title {
    @apply text-neutral-high-pure;
  }

}
.buttons {
  @apply flex;
}
</style>
