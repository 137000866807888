import type { CoreApi } from './core-api.http.types'
import http from './core-api.http'

export default {

  async me() {
    const res = await http.get<CoreApi.MeResponse>('/usuarios/me')
    return res.data.data
  },
}
