<script setup lang="ts">
import CheckIcon from '~icons/material-symbols/check-circle-outline-rounded'
import CloseIcon from '~icons/material-symbols/close-rounded'
import GenericFiletIcon from '~icons/material-symbols/docs-outline'
import ImageIcon from '~icons/material-symbols/image-outline-rounded'
import PDFIcon from '~icons/material-symbols/picture-as-pdf-outline-rounded'
import ScheduleIcon from '~icons/material-symbols/schedule-outline-rounded'
import VideoIcon from '~icons/material-symbols/video-call-outline'

const props = defineProps({
  fileTypeIcon: Array,
  fileName: Array,
  statusTypeDocument: String,
})

function getIcon(fileType: string) {
  const icon = {
    pdf: PDFIcon,
    png: ImageIcon,
    jpg: ImageIcon,
    jpeg: ImageIcon,
    webp: ImageIcon,
    mp4: VideoIcon,
    default: GenericFiletIcon,
  } as Record<string, typeof PDFIcon>

  return icon[fileType] || icon.default
}

function getStatusIcon(statusType: string) {
  const icon = {
    reproved: CloseIcon,
    approved: CheckIcon,
    done: CheckIcon,
    processing: ScheduleIcon,
    pending: ScheduleIcon,
    in_analysis: ScheduleIcon,
    pending_change: ScheduleIcon,
  } as Record<string, typeof CloseIcon>

  return icon[statusType]
}

function getStatusColor(statusType: string) {
  const color = {
    reproved: 'text-feedback-negative-pure',
    approved: 'text-feedback-positive-pure',
    done: 'text-feedback-positive-pure',
    processing: 'text-feedback-informative-pure',
    pending: 'text-feedback-informative-pure',
    in_analysis: 'text-feedback-informative-pure',
    pending_change: 'text-feedback-informative-pure',
  } as Record<string, string>

  return color[statusType]
}

function truncateFileName(fileName: string) {
  const maxChars = 6
  return fileName.length > maxChars ? `${fileName.substring(0, maxChars)}...` : fileName
}

const fieldIsDisabled = computed(() => String(props.statusTypeDocument) === 'pending' || String(props.statusTypeDocument) === 'processing' || String(props.statusTypeDocument) === 'failed')
</script>

<template>
  <div
    class="mt-2 flex p-4 border-1 rounded-md items-center gap-2 border-neutral-high-dark justify-between"
    :class="`${fieldIsDisabled ? 'bg-neutral-high-light' : ''}`"
  >
    <div class="flex gap-4 flex-wrap">
      <div
        v-for="(name, index) in props.fileName"
        :key="index"
        class="flex items-center gap-2"
        :title="String(name)"
      >
        <component
          :is="getIcon(String(props.fileTypeIcon[index]))"
          v-if="props.fileTypeIcon?.[index]"
          class="h-6 w-6 text-neutral-low-light opacity-30"
          aria-hidden="true"
        />
        <span
          v-if="props.fileName"
          class="text-neutral-low-medium text-micro"
          :class="`${fieldIsDisabled ? 'text-neutral-low-medium opacity-40' : ''}`"
        >
          {{ props.fileName.length > 1 ? truncateFileName(String(name)) : name }}
        </span>
      </div>
    </div>
    <div>
      <component
        :is="getStatusIcon(String(props.statusTypeDocument))"
        v-if="props.statusTypeDocument"
        :class="getStatusColor(String(props.statusTypeDocument))"
        aria-hidden="true"
      />
    </div>
  </div>
</template>
