/* eslint-disable jsdoc/require-returns-check */
/* eslint-disable jsdoc/check-param-names */
import type { KeycloackApi } from './keycloack-api.types'
import { REFRESH_TOKEN_STORAGE_KEY } from '~/constants'
import env from '~/env'
import storage from '~/foundation/storage/localstorage'
import { BASE_URL } from './keycloack-api.contants'
import http from './keycloack-api.http'

/**
 * Retrieves the redirect URI.
 *
 * @returns The redirect URI.
 */
export const getRedirectUri = () => `${window.location.origin}${window.location.pathname}`

/**
 * Guarda os query params no localStorage.
 */
function storeQueryParams() {
  const urlParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlParams.entries())
  storage.set('storedQueryParams', params)
}

/**
 * Limpa os query params do localStorage.
 */
function clearStoredQueryParams() {
  storage.remove('storedQueryParams')
}

/**
 * Recupera os query params do localStorage.
 */

function getStoredQueryParams() {
  const storedQueryParams = storage.get<Record<string, string>>('storedQueryParams')
  return storedQueryParams
}

/**
 * Retrieves the access token using the authorization code.
 *
 * @param code - The authorization code.
 * @returns The access token.
 */
export async function getAccessToken(code: string) {
  const redirectUri = getRedirectUri()
  const body = new URLSearchParams([
    ['grant_type', 'authorization_code'],
    ['code', code],
    ['redirect_uri', redirectUri],
    ['client_id', env.VITE_KEYCLOAK_CLIENT_ID],
  ])

  const res = await http.post<KeycloackApi.KeycloackTokenResponse>('token', body)
  return res.data
}

/**
 * Retrieves a new access token using the refresh token.
 *
 * @param refreshToken - The refresh token.
 * @returns The new access token.
 */
export async function refreshAccessToken() {
  const token = storage.get(REFRESH_TOKEN_STORAGE_KEY)

  const redirectUri = getRedirectUri()

  const body = new URLSearchParams()
  body.append('grant_type', 'refresh_token')
  body.append('redirect_uri', redirectUri)
  body.append('refresh_token', token as string)
  body.append('client_id', env.VITE_KEYCLOAK_CLIENT_ID)

  const res = await http.post('token', body)
  return res.data
}

/**
 * Retrieves the user information.
 *
 * @returns The user information.
 */
export async function getUserInfo() {
  const res = await http.get<KeycloackApi.GetUserInfoResponse>('/userinfo')
  return res.data
}

/**
 * Logout the user.
 */
export async function logout(refreshToken: string) {
  const redirectUri = getRedirectUri()
  const url = new URLSearchParams()
  url.append('redirect_uri', encodeURI(redirectUri))
  url.append('client_id', env.VITE_KEYCLOAK_CLIENT_ID)
  url.append('refresh_token', refreshToken)

  await http.post('/logout', url)
}

/**
 * Redirects the user to the Keycloak login page.
 */
function redirect() {
  const redirectUri = getRedirectUri()
  storeQueryParams()
  const url = new URLSearchParams()
  url.append('client_id', env.VITE_KEYCLOAK_CLIENT_ID)
  url.append('redirect_uri', encodeURI(redirectUri))
  url.append('response_type', 'code')
  url.append('scope', 'openid profile email')

  window.location.href = `${BASE_URL}/auth?${url}`
}

/**
 * Clean URL parameters (code and state) from the URL.
 *
 * @returns The URL without the parameters.
 */
export function cleanUrlParams() {
  const url = new URL(window.location.href)
  url.searchParams.delete('code')
  url.searchParams.delete('session_state')
  url.searchParams.delete('state')
  // restore query params
  const storedQueryParams = getStoredQueryParams()
  for (const key in storedQueryParams)
    url.searchParams.set(key, storedQueryParams[key])

  clearStoredQueryParams()
  // replace the current URL
  window.history.replaceState({}, document.title, url)
}

export default {
  cleanUrlParams,
  redirect,
  getAccessToken,
  getRedirectUri,
  getUserInfo,
  refreshAccessToken,
  storeQueryParams,
  logout,
  getStoredQueryParams,
}
