import type { FormalizationStatus } from './financing-flow/Financing'
import FinancingFlow from '~/services/financingFlow/FinancingFlow'

const config = import.meta.env

export default {
  async financingFlow(id: string) {
    try {
      const financingFlow = new FinancingFlow(useApi('financingFlow'))
      let response

      do {
        response = await financingFlow.getFinancingFlow(id)

        if (response.id)
          return response
        else
          await new Promise(resolve => setTimeout(resolve, 3000))
      } while (!response.id)
    }
    catch (error) {
      console.error(error)
    }
  },

  formalizationIsApproved(data: FormalizationStatus) {
    return data.registration === 'approved'
      && data.documentation === 'approved'
      && data.hardware === 'submitted'
      && (data.receipt_model === 'submitted' || data.receipt_model === 'approved')
      && data.contract === 'approved'
  },

  oldJourneyFinancingListRedirect(new_journey: boolean) {
    if (!new_journey) {
      window.open(`${config.VITE_URL_LEGACY}/financiamentos`, '_self')
      return true
    }

    return false
  },
}
