import axios from 'axios'

import env from '~/env'
import {
  injectAccessTokenInterceptor,
  refreshTokenResponseInterceptor,
} from '~/services-v2/keycloack-api/keycloack-api.interceptors'

const http = axios.create({
  baseURL: env.VITE_FINANCIAL_BFF_API_URL,
})
// @ts-expect-error: error is not a valid axios response
http.interceptors.request.use(injectAccessTokenInterceptor)
http.interceptors.response.use(c => c, refreshTokenResponseInterceptor)

export default http
