<!-- eslint-disable vue/valid-attribute-name -->
<script lang="ts" setup>
import type { LinkData } from './types'

defineProps<{
  id: string
  linkId?: string
  text?: LinkData['text']
  link?: LinkData['link']
  active?: LinkData['active']
  external?: LinkData['external']
  action: () => void
}>()

const emit
  = defineEmits<{
    (e: 'clicked'): void
  }>()
</script>

<template
  :key="text"
>
  <li
    :id="`menu-item-link-${id}`"
    :data-testid="`menu-item-link-${id}`"
    class="sol-menuitem-link-core"
    role="presentation"
    @click="emit('clicked')"
    @keyup.enter="emit('clicked')"
    @keyup.space="emit('clicked')"
  >
    <slot
      :id="linkId ?? id"
      :active="active"
      :link="link"
      :text="text"
      :external="external"
      class="flex"
    >
      <button
        :id="`button-item-${id}`"
        role="menuitem"
        class="pr-md"
        @click="action"
      >
        <div class="flex">
          <slot :name="`icon:${text}`" />
          {{ text }}
        </div>
      </button>
    </slot>
  </li>
</template>

<style lang="scss">
.sol-menuitem-link-core {
  @apply list-none;
  @apply inline-block;
  @apply w-full  px-4xs py-micro;
  @apply fonts-body-large-regular whitespace-nowrap;

  &:focus-within {
    @apply outline-focused;
    outline-offset: -1px;
  }

  &:hover {
    @apply bg-neutral-high-medium bg-opacity-medium;
  }

  .-active {
    @apply text-brand-secondary-dark;
  }

  a {
    @apply inline-block;
    @apply w-full;
    outline: none!important;
  }
}
</style>
