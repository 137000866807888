/**
 * KnowError
 *
 * KnowError is a class that extends Error and is used to throw errors that are known and can be handled.
 * This must me ignored by the Sentry.
 */

export default class KnowError extends Error {
  constructor(message) {
    super(message)
    this.name = 'KnowError'
  }
}
