import type { RestConnections } from '~/types'

export default {
  dev: {
    url: 'https://stg-contract-formalization.solfacil.com.br',
  },
  prod: {
    url: 'https://contract-formalization.solfacil.com.br',
  },
  stg: {
    url: 'https://stg-contract-formalization.solfacil.com.br',
  },
} as RestConnections
