<script setup lang="ts">
import { useToast } from '@solfacil/girassol'
import { defineComponent } from 'vue'
import CompanyBiometryData from '~/components/company/solfacil/BiometryData.vue'
import CompanyClientData from '~/components/company/solfacil/CompanyData.vue'
import CompanyDocumentData from '~/components/company/solfacil/DocumentData.vue'
import CompanyProjectData from '~/components/company/solfacil/ProjectData.vue'
import FormalizationReiceiptModel from '~/components/formalization/reiceipt-model/index.vue'
import FinancingFlow from '~/services/financingFlow/FinancingFlow'
import { useFinancing } from '~/store/useFinancing'
import type { CompanyFormModel, TypesErrorsCompanyDocuments } from '~/utils/company-register/CompanyRegister'
import type { BiometryAlertStatus } from '~/utils/customer-register/Biometry'
import type { StageFormalization } from '~/utils/customer-register/CustomerRegister'
import type { FinancingFlowResponse, FormalizationStatus } from '~/utils/financing-flow/Financing'
import type { HardwareCombosModel } from '~/utils/installation/Installation'
import verify from '~/utils/verify'

const props = defineProps<{
  companyForm: CompanyFormModel
  triggerRefetch: () => Promise<void>
  isOnflexPartner: boolean
  projectName: string
  hardwareForm: {
    response?: HardwareCombosModel
    refetch: () => Promise<void>
  }
}>()

defineComponent({
  components: {
    CompanyClientData,
    CompanyBiometryData,
  },
})

const { t } = useI18n()
const router = useRouter()
const id = router.currentRoute.value.params.id as string
const { createErrorToast } = useToast()
const { financing } = useFinancing(id as string)
const financingFlow = new FinancingFlow(useApi('financingFlow'))
const verifyStatusOld = ref({} as FinancingFlowResponse)

const statusDocument = ref('' as string)
const errorsCompanyDocuments = ref({} as TypesErrorsCompanyDocuments)
const statusProject = ref({} as FinancingFlowResponse)
const statusLoading = ref(true)
const statusChange = ref(false)
const solfacilPoints = ref('')
const biometryAlert = ref({
  pendencies: null,
  reproved: false,
} as unknown as BiometryAlertStatus)
const stage = ref('') as unknown as Ref<StageFormalization>
const stageReproved = ref('')
const showBannerSolfacil = ref((!statusLoading.value && statusProject.value?.points && statusProject.value?.section_statuses?.formalization?.contract === 'approved'))
const hasAlert = props.companyForm.status === 'reproved'
  || statusDocument.value === 'failed'
  || biometryAlert.value?.reproved
  || biometryAlert.value?.pendencies?.items?.length
  || showBannerSolfacil.value
  || financing?.status === 'kit_pending'
  || financing?.status === 'reproved'
  || financing?.status === 'canceled'

function sendError(...args: unknown[]): void {
  errorsCompanyDocuments.value = args[0] as TypesErrorsCompanyDocuments
}

const componentStage = ref({
  registration: {
    id: 'registration',
    name: 'CompanyClientData',
    component: CompanyClientData,
    props: {
      companyForm: props.companyForm,
      triggerRefetch: props.triggerRefetch,
      errorsCompanyDocuments,
      statusDocument,
    },
  },
  documentation: {
    id: 'documentation',
    name: 'CustomerDocumentData',
    component: CompanyDocumentData,
    props: {
      statusCompanyform: props.companyForm.status,
    },
  },
  hardware: {
    id: 'hardware',
    name: 'CompanyProjectData',
    component: CompanyProjectData,
    props: {
      projectName: props.projectName,
    },
  },
  receipt_model: {
    id: 'receipt_model',
    name: 'FormalizationReiceiptModel',
    component: FormalizationReiceiptModel,
    props: {
      isOnflexPartner: props.isOnflexPartner,
    },
  },
  contract: {
    id: 'contract',
    name: 'CompanyBiometryData',
    component: CompanyBiometryData,
    props: {},
  },
})

const components = ref([
  componentStage.value.registration,
  componentStage.value.documentation,
  componentStage.value.hardware,
  componentStage.value.receipt_model,
  componentStage.value.contract,
])

function checkStageReproved(value: FormalizationStatus) {
  Object.entries(value).forEach(([key, status]) => {
    if (status === 'reproved')
      stageReproved.value = key
  })
}

async function verifyStatus() {
  const INTERVAL_TIME = 10000
  const interval = setInterval(async () => {
    try {
      const oldStatus = verifyStatusOld.value.status
      const oldFormalization = verifyStatusOld.value.section_statuses.formalization
      const oldIsApproved = verify.formalizationIsApproved(oldFormalization)

      if (oldIsApproved || ['canceled', 'reproved', 'expired'].includes(oldStatus)) {
        clearInterval(interval)
        return
      }

      const verifyStatusNew = await financingFlow.getFinancingFlow(id)
      const newStatus = verifyStatusNew.status
      const newFormalization = verifyStatusNew.section_statuses.formalization
      const newIsApproved = verify.formalizationIsApproved(newFormalization)
      const hasStatusChanged = newStatus !== oldStatus
      const hasStatusChangedStop = ((hasStatusChanged) && newIsApproved) || ((hasStatusChanged) && ['canceled', 'reproved', 'expired'].includes(newStatus))

      verifyStatusOld.value = verifyStatusNew
      statusProject.value = verifyStatusNew

      if (hasStatusChangedStop) {
        statusLoading.value = true
        clearInterval(interval)
        checkStageReproved(newFormalization)
        setTimeout(() => statusLoading.value = false, 500)
        return
      }

      if (hasStatusChanged) {
        statusChange.value = true
        setTimeout(() => statusChange.value = false, 500)
      }
    }
    catch (error) {
      console.error(error)
      createErrorToast(t('customer.header.message.error'))
    }
  }, INTERVAL_TIME)
}

async function getStatus() {
  statusLoading.value = true

  try {
    const resp = await financingFlow.getFinancingFlow(id)
    verifyStatusOld.value = resp

    if (resp) {
      statusProject.value = resp
      if (resp.section_statuses.formalization)
        checkStageReproved(resp.section_statuses.formalization)

      if (resp?.points)
        solfacilPoints.value = `${t('customer.bannerSolfacilPlus.titlePrimary')} ${resp.points} ${t('customer.bannerSolfacilPlus.titleSecondary')}`
    }

    statusLoading.value = false
  }
  catch {
    statusLoading.value = false
  }
}

function getChangeStatus(value: FormalizationStatus | unknown) {
  statusChange.value = true
  statusProject.value.section_statuses.formalization = value as FormalizationStatus
  setTimeout(() => statusChange.value = false, 500)
}

watch(() => props.hardwareForm.response, async () => {
  let index = 0 as number
  const item = 'hardware'

  await components.value.forEach((component, key) => {
    if (component.id === item)
      index = key
  })

  components.value = components.value.filter(component => component.id !== item)

  await nextTick()
  components.value.splice(index, 0, componentStage.value[item])
})

onMounted(async () => {
  await getStatus()
  await verifyStatus()
})

function formatDate(date: string | undefined) {
  if (date)
    return new Date(`${date}T00:00:00`).toLocaleDateString('pt-BR')
  return ''
}

function stageOpenCollapse(value: StageFormalization) {
  stage.value = value

  setTimeout(() => {
    const element = document.getElementById(`accordion_${value}`)
    if (element)
      element.scrollIntoView({ behavior: 'smooth' })
  }, 500)
}

function redirectPage(value: string) {
  const routes = {
    simulation: `/simulation/details/${id}`,
    installation: `/installation/${id}`,
  }

  if (routes[value])
    router.push(routes[value])
}
</script>

<template>
  <div v-if="!statusLoading">
    <NavigationHeaderBar
      v-if="!statusChange"
      stage="formalization"
      :title="props.projectName"
      :project-status="statusProject"
      @stage="stageOpenCollapse"
    />

    <div v-if="components" class="informations">
      <div v-if="hasAlert" class="my-4 mb-8">
        <BannerSolfacilPlus v-if="showBannerSolfacil" :title="solfacilPoints" class="my-4" />

        <template v-if="financing?.status === 'canceled'">
          <SolAlert
            id="informative-feedback-canceledl"
            :title="t('customer.canceled.title')"
            feedback="error"
            class="my-4"
          >
            {{ t('customer.canceled.description') }}
          </SolAlert>
        </template>

        <template v-if="financing?.status === 'reproved'">
          <SolAlert
            v-if="financing.section_statuses?.formalization.documentation === 'reproved' || financing.section_statuses?.formalization.registration === 'reproved'"
            id="informative-feedback-receipt-model"
            :title="t('customer.titleUnableContinuefinancing')"
            feedback="error"
            class="my-4"
          >
            {{ t('customer.descriptionUnableContinuefinancing') }}
          </SolAlert>

          <SolAlert
            v-if="biometryAlert?.reproved"
            id="reproved-feedback-biometry"
            :title="t('customer.biometry.alert.reproved.title')"
            feedback="error"
            class="my-4"
          >
            {{ t('customer.biometry.alert.reproved.subtitle') }}
          </SolAlert>

          <div v-for="(item, index) in biometryAlert?.pendencies?.items" :key="index">
            <SolAlert
              id="pendencies-feedback-biometry"
              class="my-4"
              :title="item.message"
              feedback="warning"
            >
              {{ t('customer.biometry.alert.pendencies', { date: formatDate(biometryAlert?.pendencies?.expired_at) }) }}
            </SolAlert>
          </div>
        </template>

        <template v-if="financing?.status === 'kit_pending'">
          <SolAlert
            id="kit-mirror-pending"
            :title="t('project_data.alerts.kit_mirror_pending.title')"
            feedback="warning"
            class="my-4"
          >
            {{ t('project_data.alerts.kit_mirror_pending.text') }}
          </SolAlert>
        </template>
      </div>

      <component
        :is="component.component"
        v-for="(component, index) in components"
        :key="index"
        class="content-accordion"
        :position="index + 1"
        :status-project="statusProject.section_statuses?.formalization"
        :stage="stage"
        :stage-reproved="stageReproved"
        :form-data="props.hardwareForm.response"
        :refetch="props.hardwareForm.refetch"
        v-bind="component.props"
        @errors-company-documents="sendError"
        @status-biometry="value => getChangeStatus(value)"
        @alert-biometry="value => biometryAlert = value as BiometryAlertStatus"
        @client-status-document-step="value => statusDocument = value as string"
      />
    </div>

    <div class="mx-4 md:system:mx-12">
      <SolDivider thickness="x-small" orientation="horizontal" class="my-5 mt-0 md:system:my-0" />

      <div class="flex flex-col md:system:flex-row flex-col-reverse justify-between gap-5 my-4 md:system:my-12">
        <SolButton
          id="customer-back-simulation"
          size="large"
          class="w-full md:system:w-auto"
          variant="secondary"
          @click="redirectPage('simulation')"
        >
          {{ t('customer.endButtons.backSimulation') }}
        </SolButton>

        <SolButton
          id="customer-next-installation"
          size="large"
          class="w-full md:system:w-auto"
          :disabled="statusProject.section_statuses?.formalization?.contract !== 'approved'"
          @click="redirectPage('installation')"
        >
          {{ t('customer.endButtons.nextInstallation') }}
        </SolButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.informations {
  @apply p-4 md:site:px-12 md:site:pt-8;
}

.content-accordion + .content-accordion {
  @apply mt-2 lg:system:mt-8;
}
</style>
