<script setup lang="ts">
import IconOpenInNew from '~icons/material-symbols/open-in-new'

const { t } = useI18n()
const { track } = useMixpanel()

const toogleModalCep = ref(false)
const LINK_CORREIOS = 'https://buscacepinter.correios.com.br'
const LINK_VIDEO_YOUTUBE = 'https://www.youtube.com/embed/6_ZmexP8X90'

function openModalCep() {
  track('customer_data_button_problems-cep', { trigger: 'Clique no botão problemas com CEP' })
  toogleModalCep.value = true
}
</script>

<template>
  <div class="text-brand-secondary-dark flex gap-1 cursor-pointer hover:underline" @click="openModalCep">
    <span class="text-3xs">{{ t('form.informativeCepText') }}</span>
    <IconOpenInNew />
  </div>

  <SolModal
    id="modal"
    :is-open="toogleModalCep"
    :title="t('form.cepModal.title')"
    :action-primary-text="t('form.cepModal.titleButton')"
    :size="{
      desktop: 'large',
      mobile: 'bottom-sheet',
    }"
    @close="toogleModalCep = false"
    @action:primary="toogleModalCep = false"
  >
    <div>
      <iframe
        class="mb-4"
        width="100%"
        height="212"
        :src="LINK_VIDEO_YOUTUBE"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      />

      <div>
        <span class="font-bold">{{ t('form.cepModal.informative.title') }}</span>
        <ol>
          <li class="item">
            {{ t('form.cepModal.informative.item1') }} <a :href="LINK_CORREIOS" target="_blank" class="text-brand-secondary-dark hover:underline">{{ LINK_CORREIOS }} </a>
          </li>
          <li class="item">
            {{ t('form.cepModal.informative.item2') }}
          </li>
          <li class="item">
            {{ t('form.cepModal.informative.item3') }}
          </li>
          <li class="item">
            {{ t('form.cepModal.informative.item4') }}
          </li>
          <li class="item">
            {{ t('form.cepModal.informative.item5') }}
          </li>
        </ol>
      </div>
    </div>
  </SolModal>
</template>

<style lang="scss" scoped>
.item {
  @apply text-neutral-low-light;
}
</style>
