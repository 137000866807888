<script lang="ts" setup>
import IconClear from '~icons/material-symbols/close'
import type { FilterField, OptionsFilters, SelectedFilters } from '~/utils/financing-flow/Financing'

defineProps<{
  optionsFiltersList: OptionsFilters
}>()

const emit = defineEmits<(e: 'sendFilters', v: SelectedFilters) => void>()

const { t } = useI18n()

const selectedFilters = ref<SelectedFilters>({
  statusField: '',
  stepsField: '',
})

function updateSelectedFilter(filterName: string, value: FilterField) {
  selectedFilters.value[filterName] = value
  refreshNumber(filterName, value)
  emit('sendFilters', selectedFilters.value)
}

function refreshNumber(filterName: string, value: FilterField) {
  const NUMBER_IN_PARENTHESES_REGEX = /\(\d+\)/
  const NUMBER_REGEX = /\d+/

  const extractNumberFromName = (name: string): string => {
    const match = name.match(NUMBER_REGEX)
    return match ? match[0] : ''
  }

  const updateFilterName = (filter: FilterField, newNumber: string): FilterField => {
    const updatedName = filter.name.replace(NUMBER_IN_PARENTHESES_REGEX, `(${newNumber})`)
    return { name: updatedName, value: filter.value }
  }

  const targetFieldName = filterName === 'stepsField' ? 'statusField' : 'stepsField'
  const targetField = selectedFilters.value[targetFieldName]

  if (typeof targetField === 'object' && targetField.value) {
    const newNumber = extractNumberFromName(value.name)
    selectedFilters.value[targetFieldName] = updateFilterName(targetField, newNumber)
  }
}

function clearFilters() {
  Object.keys(selectedFilters.value).forEach((key) => {
    selectedFilters.value[key] = ''
  })
  emit('sendFilters', selectedFilters.value)
}
</script>

<template>
  <div class="shared-filters h-[52px] relative w-full">
    <p class="text-3xs leading-4 mb-2 md:system:mb-0">
      {{ optionsFiltersList.title }}
    </p>
    <div class="flex gap-2">
      <div
        v-for="filter in optionsFiltersList.filters"
        :key="filter.id"
        class="w-full md:system:w-auto"
      >
        <SolSelect
          v-if="filter"
          :id="filter.id"
          :class="filter.class"
          :placeholder="filter.placeholder"
          :size="filter.size"
          :name="filter.name"
          :label="filter.label"
          :options="filter.options"
          :selected="selectedFilters[filter.name]"
          @update:selected="value => updateSelectedFilter(filter.name, value)"
        />
      </div>
      <button
        v-if="selectedFilters.statusField || selectedFilters.stepsField"
        class="text-micro font-bold flex gap-1 items-center absolute right-0 top-0 md:system:static"
        @click="clearFilters"
      >
        <IconClear /> {{ selectedFilters.statusField && selectedFilters.stepsField ? t('financing.filters.clear', 0) : t('financing.filters.clear', 1) }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.shared-filters {
  :deep(.select-options-container) {
    @apply w-full md:system:w-auto md:system:min-w-56;
  }

  :deep(#select-toggle-status-field span) {
    @apply whitespace-nowrap;
  }
}
</style>
