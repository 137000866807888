import type { RestConnections } from '~/types'

export default {
  dev: {
    url: 'https://stg-solfacil-plus-api.solfacil.com.br/api/',
  },
  prod: {
    url: 'https://solfacil-plus-api.solfacil.com.br/api/',
  },
  stg: {
    url: 'https://stg-solfacil-plus-api.solfacil.com.br/api/',
  },
} as RestConnections
