<script setup lang="ts">
const props = defineProps({
  active: { default: '', type: String },
  disabled: { default: false, type: Boolean },
  firstOption: { default: () => ({ title: '', key: '' }), type: Object },
  secondOption: { default: () => ({ title: '', key: '' }), type: Object },
})
const emit = defineEmits<{
  (e: 'selected', v: string): void
}>()

onMounted(() => {
  const option = {
    key: props.active,
  }
  getClasses(option)
})

function getClasses(btnOptions: any) {
  if (props.active === '')
    return props.disabled ? 'btn-selected btn-disabled' : 'btn-selected'
  else if (btnOptions.key === props.active)
    return props.disabled ? 'btn-selected btn-disabled' : 'btn-selected'
  else
    return props.disabled ? 'btn-disabled' : ''
}
</script>

<template>
  <div class="buttons" :class="props.disabled ? 'disabled' : ''">
    <div
      id="firstOption" :key="firstOption.key" disable class="btn-selectable-left" :class="getClasses(firstOption)"
      @click="props.disabled ? null : emit('selected', firstOption.key)"
    >
      <div class="title">
        <span class="fonts-body-small-bold md:system:fonts-body-large-bold">{{ firstOption.title }}</span>
      </div>
    </div>
    <div
      :key="secondOption.key" disable class="btn-selectable-right" :class="getClasses(secondOption)"
      @click="props.disabled ? null : emit('selected', secondOption.key)"
    >
      <div class="title">
        <span class="fonts-body-small-bold md:system:fonts-body-large-bold">{{ secondOption.title }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-selectable-left {
  @apply border border-neutral-high-medium rounded-l-md;
  @apply p-nano cursor-pointer font-bold text-neutral-low-medium;
}

.btn-selectable-right {
  @apply border border-neutral-high-medium rounded-r-md;
  @apply p-nano cursor-pointer font-bold text-neutral-low-medium;
}

.btn-selected {
  @apply bg-brand-secondary-pure border-brand-secondary-pure;

  .title {
    @apply text-neutral-high-pure;
  }
}

.buttons {
  @apply flex;
}

.buttons.disabled {
  @apply opacity-75;

  >.btn-selected.btn-disabled {
    @apply bg-neutral-high-medium border-brand-primary-light;

    >.title {
      @apply text-brand-primary-medium;
    }
  }

  >.btn-disabled {
    @apply cursor-not-allowed opacity-75;
  }
}
</style>
