<script lang="ts" setup>
const emit = defineEmits<{
  (e: 'actionInterest'): void
}>()

const { t } = useI18n()
</script>

<template>
  <div
    class="interest-component flex flex-col items-center"
  >
    <img
      src="/images/period-payment.svg"
      :alt="t('simulation.result.bnpl.shortly.altImg')"
      class="object-cover w-full h-full rounded-lg w-[163px]"
    >
    <h2 class="flex gap-1 items-center justify-center">
      <span class="text-[24px] font-highlight">{{ t('simulation.result.bnpl.shortly.title') }}</span>
      <SolTag
        id="shortlyFlag"
        variant="fill"
      >
        {{ t('simulation.result.bnpl.shortly.flag') }}
      </SolTag>
    </h2>
    <p class="text-[16px] text-neutral-low-light pt-2 pb-6 text-center">
      {{ t('simulation.result.bnpl.shortly.description') }}
    </p>
    <SolButton
      id="period_payment"
      dense
      variant="secondary"
      @click="emit('actionInterest')"
    >
      {{ t('simulation.result.bnpl.shortly.textButton') }}
    </SolButton>
  </div>
</template>

<style lang="scss" scoped>
.interest-component {
  :deep(#tag-shortlyFlag) {
    @apply bg-brand-secondary-medium;
  }
}
</style>
