import type { RestConnections } from '~/types'

export default {
  dev: {
    url: 'https://kongzinho.solfacil.com.br/stg-financial-products-simulator',
  },
  prod: {
    url: 'https://kong.solfacil.com.br/prd-financial-products-simulator',
  },
  stg: {
    url: 'https://kongzinho.solfacil.com.br/stg-financial-products-simulator',
  },
} as RestConnections
