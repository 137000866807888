<script setup lang="ts">
import CloseIcon from '~icons/material-symbols/close-rounded'
import DoneIcon from '~icons/material-symbols/done-rounded'
import type { CollapseStatuses } from '~/utils/collapse'
import type { TagStep } from '~/utils/customer-register/CustomerRegister'

defineProps<{
  title: string
  position: string
  openCollapse?: boolean
  tag?: TagStep
  state?: CollapseStatuses
  blocked?: boolean
}>()

const { t } = useI18n()

const flagInProgress = t('project_data.status.in_progress')
</script>

<template>
  <div class="sol-accordion-financing">
    <div v-if="!state" class="w-10 h-10 rounded-full bg-neutral-high-pure flex items-center justify-center relative top-5 left-4 lg:system:left-8 shadow-weak">
      <span class="text-white text-[1rem] md:system:text-[1.5rem] font-bold">{{ position }}</span>
    </div>
    <div v-else-if="state === 'done'" class="w-10 h-10 rounded-full bg-feedback-positive-pure flex items-center justify-center relative top-5 left-4 lg:system:left-8 shadow-weak">
      <span class="text-white text-[1rem] md:system:text-[1.5rem] font-bold text-neutral-high-pure"><DoneIcon class="w-6 h-6" /></span>
    </div>
    <div v-else-if="state === 'pending'" class="w-10 h-10 rounded-full bg-neutral-high-pure flex items-center justify-center relative top-5 left-4 lg:system:left-8 shadow-weak border border-feedback-negative-pure">
      <span class="text-white text-[1rem] md:system:text-[1.5rem] font-bold">{{ position }}</span>
    </div>
    <div v-else-if="state === 'failed'" class="w-10 h-10 border rounded-full bg-neutral-high-pure flex items-center justify-center relative top-5 left-4 lg:system:left-8 shadow-weak border-feedback-negative-pure">
      <span class="text-white text-[1rem] md:system:text-[1.5rem] font-bold text-feedback-negative-pure"><CloseIcon class="w-6 h-6" /></span>
    </div>
    <SolAccordion
      id="accordion"
      class="accordion-mobile"
      :class="{ 'pointer-events-none icon-remove': blocked }"
      bg="bg-neutral-high-pure"
      :on-color="false"
      :open="openCollapse ? openCollapse : false"
    >
      <template #title>
        <div class="flex gap-3 items-center w-full whitespace-nowrap" :class="{ 'opacity-medium': blocked }">
          {{ title }}
          <SolTag
            v-if="tag && tag.text !== flagInProgress"
            :id="tag.id"
            :text="tag.text"
            :variant="tag.variant"
            :size="tag.size"
            :type="tag.type"
          />

          <div class="flex justify-end w-full">
            <slot name="icon" />
          </div>
        </div>
      </template>
      <slot><div /></slot>
    </SolAccordion>
  </div>
</template>

<style lang="scss">
.sol-accordion-financing {
  .sol-accordion-core{
    &.icon-remove svg.icon{
      @apply hidden;
    }
    .accordion-content{
      @apply p-xs pt-0  bg-neutral-high-pure rounded-lg;
    }
    @apply bg-neutral-high-pure;
    .summary{
      @apply p-xs bg-neutral-high-pure rounded-lg;
      .container-title {
        width: 100%;
        @apply fonts-heading-h4 md:system:fonts-heading-h3 text-neutral-low-dark;
      }
    }
  }

  .sol-accordion-core[open]{
    @apply bg-neutral-high-pure;
    .summary{
      @apply pb-0 bg-neutral-high-pure rounded-lg;
      .container-title {
        @apply fonts-heading-h4 md:system:fonts-heading-h3 text-neutral-low-dark;
      }
    }
  }
  .accordion-mobile {
    @apply w-full rounded-lg bg-neutral-high-pure z-20;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    .container-title,
    .form-client-data,
    .accordion-content div p {
      @apply -mx-4 lg:system:mx-0;
    }
    .resume-container {
      h2 {
        @apply hidden;
      }
      h3 {
        @apply text-left;
      }
    }
    .form-client-data{
      @apply my-sm
    }
  }
}
</style>
