<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import IconLock from '~icons/material-symbols/lock'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'
import { Formalization } from '~/types/enum'
import type { StageFormalization } from '~/utils/customer-register/CustomerRegister'
import download from '~/utils/download'

const props = defineProps<{
  position: number
  stage: StageFormalization
  stageReproved: string
}>()

const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'application/pdf']
const ACCEPTED_FILE_TYPES_STRING = `.${ACCEPTED_FILE_TYPES.map(type => type.split('/')[1]).join(', .')}`

const loadingLocal = ref(false)
const { t } = useI18n()

const initialValues = {
  uploadIdentifier: null,
}

const validationSchema = toTypedSchema(
  zod.object({
    uploadIdentifier: zod
      .any()
      .refine(uploadIdentifier => uploadIdentifier?.length === 1, t('form.file_required'))
      .refine(uploadIdentifier => uploadIdentifier?.every(file => ACCEPTED_FILE_TYPES.includes(file.type)), t('form.invalid_file_type', { types: ACCEPTED_FILE_TYPES_STRING })),
  }),
)

const { handleSubmit, validate } = useForm({
  validationSchema,
  initialValues,
})

const loadingUpload = ref(false)
const blocked = ref(true)

const saveDocument = handleSubmit(async () => {
  loadingUpload.value = true
  const { valid } = await validate()
  if (valid) {
    try {
      // @TODO when back define
      // call api
      // eslint-disable-next-line no-console
      console.log('Call api save document')
      loadingUpload.value = false
    }
    catch {
      loadingUpload.value = false
    }
    finally {
      loadingUpload.value = false
    }
  }
})

onMounted(() => {
  checkStageReproved()
})

// watch(values, (newValues) => {
// validateField(newValues, errors.value, initialValues)
// @TODO when back define
// call api updateDebounced (field)
// })

// function validateField(
//   changedValues: any,
//   errors: any,
//   initialValues: any,
// ) {
/*
  const changedValuesObject = toRaw(changedValues)

  for (const [key, value] of Object.entries(changedValuesObject)) {
     const isValueChanged = value !== initialValues[key]
     const hasError = errors[key]?.length > 0
     @TODO when back define
     return filtered objects
  */
// }

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.DOCUMENTATION
}
</script>

<template>
  <CustomerElementAccordion
    v-if="!loadingLocal"
    :id="`accordion_${Formalization.DOCUMENTATION}`"
    :title="t('customer.document_data_title')"
    :position="String(props.position)"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.DOCUMENTATION"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <div class="mt-2">
      <p>{{ t('customer.document_data_subtitle') }}</p>
    </div>
    <div class="form-client-data">
      <form>
        <SolFileUpload
          id="uploadIdentifier"
          :class="`upload-file ${loadingUpload ? 'pointer-events-none opacity-60' : ''}`"
          name="uploadIdentifier"
          :use-field="useField"
          :download-menu="['download']"
          :accept="ACCEPTED_FILE_TYPES.join(',')"
          :multiple="true"
          :placeholder="t('formDocument.placeholder')"
          :helper-text="t('formDocument.helperText')"
          :label="t('formDocument.labelDocument')"
          :disabled="true"
          download-src=""
          @menu:download="download.downloadFile('identityURL')"
        />

        <div class="flex justify-end border-t border-neutral-high-medium pt-6 mt-8">
          <SolButton
            id="document_confirm"
            :loading="loadingUpload"
            size="large"
            @click="saveDocument"
          >
            {{ t('app.send') }}
          </SolButton>
        </div>
      </form>
    </div>
  </CustomerElementAccordion>
</template>

<style lang="scss" scoped>
.error-message {
  @apply text-feedback-negative-pure py-nano;
  @apply fonts-subtitle-small;
}

.form-client-data {
  @apply mt-sm mb-0;
}

.upload-file + .upload-file {
  @apply mt-4;
}
</style>
