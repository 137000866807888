<script setup lang="ts">
import IMaterialSymbolsInfoOutline from '~icons/material-symbols/info-outline'
import IMaterialSymbolsWorkspacePremiumOutline from '~icons/material-symbols/workspace-premium-outline'
import type { Project } from '~/utils/project/Project'
import type { AddonOpportunity, InstallmentsWithBenefits, ParsedInstallment, SimulationOpportunities } from '~/utils/simulation/Simulation'
import type { InstallmentResponse } from '~/utils/simulation/SimulationApi'

const props = defineProps<{
  project: Project
  loadingDetails: boolean
  disableFields: boolean
  installmentList: InstallmentResponse | null
  refetchInstallment: () => Promise<any[] | void>
  setSelected: (value: any, is_user_action?: boolean) => void
  hasDefaultInstallment: boolean
  isProjectDisabled?: boolean
  showEmptyStateNotInstallments: boolean
  preSelectedInstallment?: 'with_combo' | 'with_combo_comission' | 'without_combo' | 'unselected'
  loadingAddons: boolean
  financingValue: number
  personType: string
}>()

const { formatMoney } = useMoney()

const { getSimulationOpportunities } = useResumeStore()

function installmentListAdapter(installments: InstallmentResponse | null): InstallmentsWithBenefits | null {
  if (!installments)
    return null

  const comboAddonOriginal = installments.original_simulation?.addons?.find((addon) => {
    return addon.type === 'combo'
  })

  const comboAddonSuggested = installments.suggested_simulation?.addons?.find((addon) => {
    return addon.type === 'combo'
  })

  if (!comboAddonOriginal && !comboAddonSuggested)
    return null

  const mappedProposal: ParsedInstallment = {
    ...installments.original_simulation.proposal.proposal,
    ...installments.original_simulation.proposal.parameters,
    simulation_id: installments.original_simulation.id,
    engine_simulation_id: installments.original_simulation.proposal.engine_simulation_id || '0',
    id: installments.original_simulation.id,
    combo: false,
    is_from_suggested: false,
    difference: 0,
  }

  const installmentWithComboPositiveDifferenceOriginal = comboAddonOriginal?.installment_price ? -comboAddonOriginal.installment_price : 0

  const mappedProposalWithComboAddon: ParsedInstallment = {
    ...installments.original_simulation.proposal.proposal,
    ...installments.original_simulation.proposal.parameters,
    simulation_id: installments.original_simulation.id,
    engine_simulation_id: comboAddonOriginal?.engine_simulation_id || '0',
    id: installments.original_simulation.id,
    combo: true,
    difference: installmentWithComboPositiveDifferenceOriginal,
    installments_value: (installments.original_simulation.proposal.proposal.installments_value - installmentWithComboPositiveDifferenceOriginal),
    monthly_interest_rate: comboAddonOriginal?.monthly_interest_rate || 0,
    cet: comboAddonOriginal?.cet || 0,
    is_from_suggested: false,
  }

  const installmentWithComboPositiveDifferenceSugggest = comboAddonSuggested?.installment_price ? -comboAddonSuggested?.installment_price : 0
  const mappedProposalWithComboComissionAddon: ParsedInstallment = {
    ...installments.suggested_simulation?.proposal?.proposal,
    ...installments.suggested_simulation?.proposal?.parameters,
    simulation_id: installments.suggested_simulation?.id,
    engine_simulation_id: comboAddonSuggested?.engine_simulation_id || '0',
    id: installments.suggested_simulation?.id,
    combo: true,
    difference: installmentWithComboPositiveDifferenceSugggest,
    installments_value: (installments.suggested_simulation?.proposal?.proposal?.installments_value - installmentWithComboPositiveDifferenceSugggest),
    monthly_interest_rate: comboAddonSuggested?.monthly_interest_rate || 0,
    cet: comboAddonSuggested?.cet || 0,
    is_from_suggested: true,
  }

  const installmentsParsed: InstallmentsWithBenefits = {
    with_benefit_combo: [mappedProposalWithComboAddon],
    with_benefit_combo_comission: [mappedProposalWithComboComissionAddon],
    without_benefit: [mappedProposal],
  }

  return installmentsParsed
}

// installments details
const headerWithCombo = {
  installments_value: { text: 'Parcela', width: '80px' },
  difference: { text: 'Economia por parcela', width: '120px' },
  combo: { text: 'Com benefícios', hide: true },
}

const headerWithComboComission = {
  installments_value: { text: 'Parcela', width: '80px' },
  difference: { text: 'Economia por parcela', width: '120px' },
  combo: { text: 'Com benefícios', hide: true },
}

const header = {
  installments_value: { text: 'Parcela', width: '80px' },
  difference: { text: 'Economia por parcela', width: '120px' },
}

const positions = {
  desktop: [
    'installments_value',
    'difference',
  ],
  mobile: [
    ['installments_value', 'difference'],
  ],
}

const positionWithCombo = {
  desktop: [
    'installments_value',
    'difference',
    'combo',
  ],
  mobile: [
    ['installments_value', 'difference'],
    ['combo'],
  ],
}

const positionWithComboComission = {
  desktop: [
    'installments_value',
    'difference',
    'combo',
  ],
  mobile: [
    ['installments_value', 'difference'],
    ['combo'],
  ],
}

onBeforeMount(async () => {
  setPreSelectedInstallment()
})

const installments = ref<any | []>(installmentListAdapter(props.installmentList))
const installmentWithComboId = ref<string | undefined>(installments.value?.with_benefit_combo[0].id)
const installmentWithComboComissionId = ref<string | undefined>(installments.value?.with_benefit_combo_comission[0].id)
const preSelectedInstallmentId = ref<string | undefined>()
const withComboSelected = ref()
const withComboComissionSelected = ref()
const withoutComboSelected = ref()

const pointsCombo = ref<AddonOpportunity>()
const responseSimulationOpportunities = ref<SimulationOpportunities>()

function setPreSelectedInstallment() {
  if (props.preSelectedInstallment === 'with_combo' && installments.value) {
    const defaultSelected = toRaw(installments.value?.with_benefit_combo[0])
    props.setSelected(defaultSelected)

    preSelectedInstallmentId.value = installments.value?.with_benefit_combo[0].id

    withComboSelected.value = { id: installmentWithComboId.value ?? null }

    return
  }

  if (props.preSelectedInstallment === 'with_combo_comission' && installments.value) {
    const defaultSelected = toRaw(installments.value?.with_benefit_combo_comission[0])
    props.setSelected(defaultSelected)

    preSelectedInstallmentId.value = installments.value?.with_benefit_combo_comission[0].id

    withComboComissionSelected.value = { id: installmentWithComboComissionId.value ?? null }
  }

  if (props.preSelectedInstallment === 'without_combo' && installments.value) {
    const defaultSelected = toRaw(installments.value?.without_benefit[0])
    props.setSelected(defaultSelected)

    preSelectedInstallmentId.value = installments.value?.without_benefit[0].id

    withoutComboSelected.value = { id: preSelectedInstallmentId.value ?? null }
  }
}

onBeforeMount(async () => {
  await simulationOpportunities()
})

async function simulationOpportunities() {
  responseSimulationOpportunities.value = getSimulationOpportunities()

  if (responseSimulationOpportunities.value) {
    pointsCombo.value = responseSimulationOpportunities.value.addons_opportunities.find((opportunity: AddonOpportunity) => {
      return opportunity.addon_type === 'combo'
    })
  }
}

function isMobileDevice() {
  return window.innerWidth < 768
}

onMounted(() => {
  showComboCommission()
})

const showComboCommissionInstallment = ref()

async function showComboCommission() {
  showComboCommissionInstallment.value = await useFlag('commi-combo-installment', { partner_id: props?.project?.partner_id })
}
</script>

<template>
  <div v-if="props.installmentList">
    <a href="https://loja.solfacil.com.br/" target="_blank" title="Click para conhecer a nossa loja" class="benefits-container">
      <div class="benefits-details">
        <img src="/images/cf-icon.png" class="icon-cf" alt="">
        <div class="text-neutral-high-pure w-full">
          <p class="text-[14px] leading-4">
            <b>{{ $t('installments.banner.title') }}</b> {{ $t('installments.banner.start') }} <span class="underline decoration-1">{{ $t('installments.banner.link') }}</span> {{ $t('installments.banner.end') }}
          </p>
        </div>
      </div>
    </a>

    <!-- With Combo -->
    <h2 class="title-installment">
      <div class="content">
        <span class="label">{{ $t('installments.with_combo') }}</span>
        <span
          v-if="pointsCombo?.gained_points && responseSimulationOpportunities?.financing_gained_points"
          class="points"
        >
          <IMaterialSymbolsWorkspacePremiumOutline class="icon" />
          <span><strong>+{{ (responseSimulationOpportunities?.financing_gained_points + pointsCombo.gained_points).toLocaleString('pt-BR') }} pontos </strong> no Solfácil Mais</span>
        </span>
      </div>
      <small class="description">{{ $t('installments.with_combo_description') }}</small>
    </h2>
    <SolList
      id="selectedInstallment"
      class="list-style"
      :class="[{ disabled: disableFields || isProjectDisabled || loadingAddons }]"
      :loading="loadingDetails"
      aria-label="list of users"
      fallback-value="-"
      select-mode="radio"
      :disabled="disableFields || isProjectDisabled || loadingAddons"
      :data="installments.with_benefit_combo"
      :selected="withComboSelected"
      :hide-menu="true"
      :headers="headerWithCombo"
      :sort-positions="positionWithCombo"
      @listitem:click="() => {}"
      @listitem:selected="(value) => setSelected(value, true)"
    >
      <template #header:id="{ data }">
        <strong>{{ data.key }}</strong>
      </template>

      <template #value:installments_value="{ data }">
        {{ formatMoney(data.value) }}
      </template>

      <template #value:difference="{ data }">
        <span class="text-brand-secondary-pure">
          {{ formatMoney(data.value) }}
        </span>
      </template>

      <template #column:combo="{ data }">
        <div v-if="data.value" class="flex w-full justify-end">
          <span class="ticker">Combo Fácil</span>
        </div>
        <span v-else />
      </template>
    </SolList>

    <!-- With Combo + Comission -->
    <template v-if="showComboCommissionInstallment && props.installmentList.suggested_simulation">
      <h2 class="title-installment">
        <div class="content">
          <div class="flex items-center">
            <span class="label">
              {{ $t('installments.with_combo_comission') }}
            </span>
            <SimulationTooltip
              :position="isMobileDevice() ? 'left' : 'right'"
              text="A comissão é fixa, e não é somada a comissão disponível no campo acima"
            >
              <IMaterialSymbolsInfoOutline class="ml-1.5" />
            </SimulationTooltip>
          </div>
          <span
            v-if="pointsCombo?.gained_points && responseSimulationOpportunities?.financing_gained_points"
            class="points"
          >
            <IMaterialSymbolsWorkspacePremiumOutline class="icon" />
            <span>
              <strong>
                +{{ (responseSimulationOpportunities?.financing_gained_points + pointsCombo.gained_points).toLocaleString('pt-BR') }} pontos
              </strong> no Solfácil Mais
            </span>
          </span>
        </div>
        <small class="description">{{ $t('installments.with_combo_comission_description') }}</small>
      </h2>

      <SolList
        id="selectedInstallment"
        class="list-style"
        :class="[{ disabled: disableFields || isProjectDisabled || loadingAddons }]"
        :loading="loadingDetails"
        aria-label="list of users"
        fallback-value="-"
        select-mode="radio"
        :disabled="disableFields || isProjectDisabled || loadingAddons"
        :data="installments.with_benefit_combo_comission"
        :selected="withComboComissionSelected"
        :hide-menu="true"
        :headers="headerWithComboComission"
        :sort-positions="positionWithComboComission"
        @listitem:click="() => {}"
        @listitem:selected="(value) => setSelected(value, true)"
      >
        <template #header:id="{ data }">
          <strong>{{ data.key }}</strong>
        </template>

        <template #value:installments_value="{ data }">
          {{ formatMoney(data.value) }}
        </template>

        <template #value:difference="{ data }">
          <span class="text-brand-secondary-pure">
            {{ formatMoney(data.value) }}
          </span>
        </template>

        <template #column:combo="{ data }">
          <div v-if="data.value" class="flex w-full justify-end">
            <span class="ticker">Combo Fácil</span>
          </div>
          <span v-else />
        </template>
      </SolList>
    </template>
    <!-- Without Combo -->
    <h2 class="title-installment">
      <div class="content">
        <span class="label">{{ $t('installments.without_combo') }}</span>
        <span
          v-if="responseSimulationOpportunities?.financing_gained_points"
          class="points"
        >
          <IMaterialSymbolsWorkspacePremiumOutline class="icon" />
          <span><strong>+{{ responseSimulationOpportunities?.financing_gained_points?.toLocaleString('pt-BR') }} pontos </strong> no Solfácil Mais</span>
        </span>
      </div>
      <small class="description">{{ $t('installments.without_combo_description') }}</small>
    </h2>
    <SolList
      id="selectedInstallment"
      :loading="loadingDetails"
      aria-label="list of users"
      fallback-value="-"
      select-mode="radio"
      class="list-style"
      :class="[{ disabled: disableFields || isProjectDisabled || loadingAddons }]"
      :disabled="disableFields || isProjectDisabled || loadingAddons"
      :selected="withoutComboSelected"
      :data="installments.without_benefit"
      :hide-menu="true"
      :headers="header"
      :sort-positions="positions"
      @listitem:click="() => {}"
      @listitem:selected="(value) => setSelected(value, true)"
    >
      <template #header:id="{ data }">
        <strong>{{ data.key }}</strong>
      </template>
      <template #value:installments_value="{ data }">
        <span>{{ formatMoney(data.value) }}</span>
      </template>
      <template #value:difference>
        <span>{{ formatMoney(0) }}</span>
      </template>
    </SolList>
  </div>

  <div v-else-if="hasDefaultInstallment && !props.showEmptyStateNotInstallments" class="empty-installment">
    <img src="/images/not-found.png" class="not-found mb-4xs">
    <h1 class="text-brand-primary-dark fonts-heading-h2 mb-4xs">
      Não foi possível exibir os dados
    </h1>
    <p class="fonts-body-x-large-regular text-brand-primary-medium mb-4xs">
      No momento estamos com instabilidade no sistema. Por favor tente novamente em alguns minutos.
    </p>
    <SolButton
      id="empty"
      type="submit"
      size="large"
      variant="secondary"
      @click="refetchInstallment"
    >
      Tentar novamente
    </SolButton>
  </div>

  <SolEmptyState
    v-else-if="hasDefaultInstallment && props.showEmptyStateNotInstallments"
    id="just-test"
    title="Sem parcelas disponíveis"
    subtitle="Não há parcelas para essa condição. Altere o prazo e veja outras opções de parcelas disponíveis."
    variant="list"
  />
</template>

<style lang="scss" scoped>
.empty-installment{
  @apply flex flex-col justify-center text-center items-center;
}

.title-installment {
  @apply py-2 flex flex-col;

  .content {
    @apply flex flex-col md:site:flex-row md:site:gap-2;

    .label {
      @apply font-bold text-3xs;
    }

    .points {
      @apply text-brand-secondary-dark flex items-center text-micro;

      .icon {
        @apply w-[16px] h-[16px] inline mr-1.5;
      }
    }
  }

  .description {
    @apply text-neutral-low-light text-micro;
  }
}

.benefits-container{
    @apply w-full my-6 grid items-center rounded-lg bg-brand-secondary-pure;
    .benefits-details{
      @apply flex items-center p-nano;
      @screen md:site {
        @apply col-span-2 px-2xs;
      }
    }
    .check-box{
      @apply p-4xs bg-neutral-high-pure border rounded-sm border-brand-primary-light gap-4 text-center flex  items-center col-span-1 justify-between;

      .installment-discount{
        @apply flex flex-col justify-center align-middle;
      }
    }
  }

.icon-cf{
  @apply ml-0 mr-nano;
  @screen md:site {
    @apply mr-4xs mb-3.5;
  }

  @apply text-md;
}

.disabled {
  @apply pointer-events-none opacity-75;
}
</style>
