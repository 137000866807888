<script setup lang="ts">
import type { FunctionalComponent, SVGAttributes } from 'vue'
import { SolHeader } from '@solfacil/girassol'
import IconMoney from '~icons/girassol/attach-money'
import { useCollapseStore } from '~/store/collapse'
import useAuth from '~/store-v2/auth/useAuth'
import useLogout from '~/store-v2/auth/useLogout'

const store = useCollapseStore()
const route = useRoute()

const { data: auth } = useAuth()
const { mutateAsync: logout } = useLogout()

const user = {
  profile: auth.value!.me.perfil,
  completeName: auth.value!.me.nomeCompleto,
}

interface Product {
  id: 'portal' | 'shop' | 'financing' | 'ampera'
  // eslint-disable-next-line ts/no-empty-object-type
  icon?: FunctionalComponent<SVGAttributes, {}>
  title: string
  // eslint-disable-next-line ts/no-unsafe-function-type
  action?: Function
}

const product = {
  id: 'financing',
  icon: IconMoney,
  title: 'Financiamento',
  action: () => window.location.href = origin,
} as Product

const partner = computed(() => ({
  partnerId: auth.value!.me.parceiro?.id || '',
  responsible: {
    cellPhone: auth.value!.me.parceiro?.responsavel?.telefone || '',
    noAccountManager: auth.value!.me.parceiro?.responsavel?.semGc || false,
  },
  solfacilPlus: auth.value!.solfacilplus,
}))

async function validateUserAcceptedTerm() {
  const accepted = auth.value!.me.parceiro?.acceptedCurrentOperatingAgreement || true
  const profile = auth.value!.me.perfil

  if (!profile)
    return

  if (accepted && ['gerente', 'integrador_light'].includes(profile))
    return

  if (!accepted && ['gerente', 'integrador_light'].includes(profile)) {
    window.location.href
      = `https://integrador.solfacil.com.br/term?redirect=https://financiamento.solfacil.com.br${route.fullPath}`
  }
}

onMounted(async () => {
  await validateUserAcceptedTerm()
})

const isOpenSidebar = ref(false)
</script>

<template>
  <main class="default-layout">
    <NavigationSidebar :is-open-sidebar="isOpenSidebar" />
    <div v-if="store.isCollapse" class="main-content">
      <SolHeader
        :user="user"
        :partner="partner"
        :product="product"
        @click-logout="logout"
        @click-menu="isOpenSidebar = !isOpenSidebar"
      />

      <RouterView />
    </div>
    <div v-else class="main-content hidden md:system:block">
      <SolHeader
        :user="user"
        :partner="partner"
        :product="product"
        @click-logout="logout"
        @click-menu="isOpenSidebar = !isOpenSidebar"
      />

      <RouterView />
    </div>
  </main>
</template>

<style lang="scss">
.main-content {
  background-color: #F0F0F0;
}
.default-engine {
  @apply m-lg;
}
.default-layout {
  @apply relative;
  @apply block max-h-screen;
  @screen md:system {
    @apply flex overflow-hidden;
  }
  > .main-content {
    @apply w-full;
    @apply overflow-auto;
    min-height: calc(100vh);
    > .page {
      @apply flex-1;
    }
  }
}
</style>
