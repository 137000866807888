<script setup lang="ts">
import IMaterialSymbolsCheckCircleRounded from '~icons/material-symbols/check-circle-rounded'

interface OptionsButtonAmpera {
  title: string
  description?: string
  sub_description: string
  key: string
  disabled?: boolean
}
const props = defineProps({
  options: { default: '', type: Array<OptionsButtonAmpera> },
  active: { default: '', type: String },
  disabled: { default: false, type: Boolean },
})
const emit = defineEmits<{
  (e: 'selected', v: string): void
}>()

function getClasses(btnOptions: any) {
  if (btnOptions.disabled)
    return 'btn-disabled'

  else if (btnOptions.key === props.active)
    return 'btn-selected'

  else
    return ''
}
</script>

<template>
  <div class="buttons">
    <div
      v-for="btn in options"
      :key="btn.key"
      disable
      class="btn-selectable"
      :data-testid="`btn-key-${btn.key}`"
      :class="getClasses(btn)"
      :title="btn.disabled ? 'Esta função não está disponível no momento' : ''"
      @click="btn.disabled ? null : emit('selected', btn.key)"
    >
      <div class="flex items-center">
        <div class="title">
          {{ btn.title }}
        </div>
      </div>
      <IMaterialSymbolsCheckCircleRounded v-if="btn.key === active" class="icon-check" />
      <div class="description">
        {{ btn.description }}
      </div>
      <div class="sub-description">
        {{ btn.sub_description }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.buttons {
  @apply pt-6;
  @apply grid gap-4;
  @screen md:system {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
  > .btn-selectable {
    @apply relative;
    @apply p-4;
    @apply border-2 rounded-2xl border-neutral-high-medium;
    @apply hover:border-2 hover:border-neutral-low-pure cursor-pointer;
    > .title {
      @apply flex justify-between;
      @apply fonts-body-x-large-bold;
      @apply text-neutral-low-dark text-[16px];
      @apply pb-1;
    }

    > .waiting{
      @apply absolute top-4 right-4;
      @apply bg-feedback-informative-light text-stroke-feedback-informative-dark px-nano py-quark rounded-full;
      @apply text-[12px];

    }
    > .icon-check {
      @apply absolute top-2 right-2;
      @apply text-brand-secondary-pure
      @apply text-xs;
    }
    > .description {
      @apply fonts-body-large-regular;
      @apply text-neutral-low-dark text-[14px];
      @apply pb-3;
    }
    > .sub-description {
      @apply fonts-body-medium-regular;
      @apply text-neutral-low-light;
    }
  }
  > .btn-selected {
    @apply border-2 border-neutral-low-pure;
  }
  > .btn-disabled {
    @apply cursor-not-allowed opacity-75;
    @apply hover:border-neutral-high-medium hover: border-2;
  }
}
</style>
