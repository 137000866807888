import Cookies from 'js-cookie'

export default {
  clear(): void {
    const cookies = Cookies.get()
    Object.keys(cookies).forEach((key) => {
      Cookies.remove(key)
    })
  },
  get(key: string): null | string {
    const value = Cookies.get(key)
    if (!value)
      return null

    return value
  },

  remove(key: string): void {
    Cookies.remove(key)
  },

  set(key: string, value: string, options?: Cookies.CookieAttributes) {
    Cookies.set(key, value, options)
  },
}
