function validateCNPJ(value: string): boolean {
  const cnpj = formatCNPJ(value)
  if (!isValidFormat(cnpj))
    return false

  const firstVerificationDigit = calculateVerificationDigit(cnpj, 12)
  const secondVerificationDigit = calculateVerificationDigit(cnpj, 13)

  if (firstVerificationDigit !== Number(cnpj.charAt(12)) || secondVerificationDigit !== Number(cnpj.charAt(13)))
    return false

  return true
}

function formatCNPJ(cnpj: string): string {
  return cnpj.replace(/\D+/g, '')
}

function isValidFormat(cnpj: string): boolean {
  return !(cnpj === '' || cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj))
}

function calculateVerificationDigit(cnpj: string, length: number): number {
  let sum = 0
  let pos = length - 7

  for (let i = length; i >= 1; i--) {
    sum += Number(cnpj.charAt(length - i)) * pos--
    if (pos < 2)
      pos = 9
  }

  const result = sum % 11
  return result < 2 ? 0 : 11 - result
}

export default validateCNPJ
