<script setup lang="ts">
import { useToast } from '@solfacil/girassol'
import RegisterService from '~/services/customer-register/CustomerRegister'

const props = defineProps({
  statusStep: {
    type: String,
    required: true,
  },
  stepToCancel: {
    type: String,
    required: true,
  },
  disabledButtonByStatus: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits<{
  (e: 'stepCanceled'): void
}>()

const { track } = useMixpanel()

const { t } = useI18n()
const router = useRouter()
const registerService = new RegisterService(useApi('customerRegister'))
const toggleModalCancel = ref(false)
const toast = useToast()
const disabledButton = ref(false)

async function cancelAnalysis() {
  track('customer_data_modal_confirm-cancel', { trigger: 'Clique no botão no mocal de cancelar análise' })

  try {
    await registerService.cancel_analysis(String(router.currentRoute.value.params.id), props.stepToCancel)

    toast.createSuccessToast({
      title: '',
      description: t('form.cancelAnalysis.successToast'),
    })

    emit('stepCanceled')
    disabledButton.value = true
  }
  catch (error) {
    console.error(error)
    toast.createErrorToast({
      description: t('form.cancelAnalysis.errorToastDescription'),
    })
    disabledButton.value = false
  }
  finally {
    toggleModalCancel.value = false
    disabledButton.value = false
  }
}

function openModalCancel() {
  track('customer_data_button_cancel-analysis', { trigger: 'Clique no botão cancelar análise' })
  toggleModalCancel.value = true
}
</script>

<template>
  <SolButton
    :id="`cancel_analysis-${props.stepToCancel}`"
    class="w-full md:system:w-auto order-2 md:system:order-1"
    variant="secondary"
    size="large"
    :disabled="statusStep !== 'under_analysis' || disabledButton || disabledButtonByStatus"
    @click="openModalCancel"
  >
    {{ t('app.cancel') }}
  </SolButton>

  <SolModal
    :id="`modal-${props.stepToCancel}`"
    :is-open="toggleModalCancel"
    :title="t('form.cancelAnalysis.title')"
    :action-primary-text="t('form.cancelAnalysis.confirm')"
    :action-secondary-text="t('form.cancelAnalysis.back')"
    :size="{
      desktop: 'small',
      mobile: 'bottom-sheet',
    }"
    @close="toggleModalCancel = false"
    @action:primary="cancelAnalysis"
    @action:secondary="toggleModalCancel = false"
  >
    <div>
      {{ t('form.cancelAnalysis.message') }}
    </div>
  </SolModal>
</template>
