import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import type { Address, AddressFormRequest, AddressOptions, ClientDataOptions, Document, DocumentModel, FinancingFormModel, Options, PersonForm, ResponseUpdatePerson, UpdateFormPartialResponse } from '~/utils/customer-register/CustomerRegister'
import BaseService from '../BaseService'

export default class CustomerRegisterService extends BaseService {
  async get_customer_registration_form(id: string, projectPersonType): Promise<ResponseModel<FinancingFormModel>> {
    try {
      const response = await super.api<ResponseModel<FinancingFormModel>>(`/registration/form/${id}/${projectPersonType}`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async cancel_analysis(project_id: string, step: string): Promise<ResponseUpdatePerson> {
    try {
      const response = await super.api<ResponseUpdatePerson>(`/${step}/${project_id}/cancel-analysis`, {
        method: 'PUT',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async update_form_partial(body: UpdateFormPartialResponse): Promise<ResponseUpdatePerson> {
    try {
      const response = await super.api<ResponseUpdatePerson>('/registration/form/partial', {
        method: 'PUT',
        body: {
          ...body,
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async registration_confirm(body: UpdateFormPartialResponse): Promise<any> {
    try {
      const response = await super.api<ResponseModel<null>>('/registration/form/confirm', {
        method: 'POST',
        body: {
          ...body,
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_customer_register_client_data_options(): Promise<ResponseModel<ClientDataOptions>> {
    try {
      const response = await super.api<ResponseModel<ClientDataOptions>>('/registration/combos?nationality=true&gender=true&occupation=true', {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async confirm_person_form(project_id: string, person_form: PersonForm): Promise<ResponseModel<null>> {
    try {
      const response = await super.api<ResponseModel<null>>('/registration/person/confirm', {
        method: 'POST',
        body: {
          project_id,
          person_form: { ...person_form },
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async confirm_address_form(project_id: string, plant_address_form: AddressFormRequest, residential_address_form: AddressFormRequest, residential_is_the_same_of_plant: boolean): Promise<ResponseModel<null>> {
    try {
      const residential_based_on_plant = residential_is_the_same_of_plant ? residential_address_form : plant_address_form
      const response = await super.api<ResponseModel<null>>('/registration/address/confirm', {
        method: 'POST',
        body: {
          project_id,
          residential_is_the_same_of_plant,
          plant_address_form: residential_based_on_plant,
          residential_address_form,
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_address(cep: string): Promise<ResponseModel<Address>> {
    const formattedCep = cep.replace('-', '')

    try {
      const response = await super.api<ResponseModel<Address>>(`/registration/address/from-cep/${formattedCep}`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_states_options(): Promise<ResponseModel<AddressOptions>> {
    try {
      const response = await super.api<ResponseModel<AddressOptions>>('/registration/combos?state=true', {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_cities_based_on_state(state_id: number): Promise<ResponseModel<Options[]>> {
    try {
      const response = await super.api<ResponseModel<Options[]>>(`/registration/cities/${state_id}`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async confirm_project_data_form(project_id: string, project_data_form: any): Promise<ResponseModel<null>> {
    try {
      const response = await super.api<ResponseModel<null>>('/registration/hardware', {
        method: 'POST',
        body: {
          project_id,
          project_data_form: { ...project_data_form },
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_is_combo(projectId: string): Promise<ResponseModel<boolean>> {
    try {
      const response = await super.api<ResponseModel<boolean>>(`/registration/financing/${projectId}/is_combo`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_documents(projectId: string): Promise<DocumentModel> {
    try {
      const response = await super.api<DocumentModel>(`/document_form/${projectId}/pf`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async upload_documents(projectId: string, type: string, body: FormData): Promise<ResponseModel<Document>> {
    try {
      const response = await super.api<ResponseModel<Document>>(`/document_form/${projectId}/${type}/upload/pf`, {
        method: 'POST',
        body,
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }
}
