import { defineStore } from 'pinia'
import type { Addons } from '~/utils/simulation/Simulation'

export const useAddonStore = defineStore('addon', () => {
  const ampera = ref<Addons[]>([])
  const insurance = ref<Addons[]>([])
  const combo = ref<Addons[]>([])

  const addons_list = computed(() => combo.value.concat(ampera.value).concat(insurance.value))

  function toggleAmpera(slug: string) {
    const updatedAmpera = ampera.value.map((product: Addons) => {
      return { ...product, applied: slug === product.product_slug ? !product.applied : product.applied }
    })

    ampera.value = updatedAmpera
  }

  function toggleInsurance(slug: string) {
    const updatedInsurance = insurance.value.map((product: Addons) => {
      return { ...product, applied: slug === product.product_slug ? !product.applied : product.applied }
    })

    insurance.value = updatedInsurance
  }

  function toggleCombo(is_applied: boolean) {
    const updatedCombo = combo.value.map((product: Addons) => {
      return { ...product, applied: is_applied }
    })

    combo.value = updatedCombo
  }

  function setAmpera(newAmpera: Addons[], imperativeValue?: boolean, canSelectAmpera?: boolean) {
    if (newAmpera.length === 0)
      return ampera.value = []

    if (imperativeValue && canSelectAmpera)
      return ampera.value = newAmpera.map((ampera) => { return { ...ampera, applied: imperativeValue } })

    if (!canSelectAmpera)
      return ampera.value = newAmpera.map((ampera) => { return { ...ampera, applied: false } })

    return ampera.value = newAmpera
  }

  function setCombo(newCombo: Addons[]) {
    combo.value = newCombo
  }

  function setInsurance(newInsurance: Addons[]) {
    if (newInsurance.length === 0)
      return insurance.value = []

    insurance.value = newInsurance
  }

  function getAddonsApplieds() {
    const addons = insurance.value.concat(ampera.value).concat(combo.value)
    return addons.filter((addon: Addons) => addon.applied)
  }

  function getCombo() {
    return combo.value
  }

  function getAmperaValue(): Addons[] {
    return ampera.value
  }

  function getAddonsList() {
    const addons = insurance.value.concat(ampera.value)
    return addons
  }

  return {
    addons_list,
    toggleAmpera,
    toggleCombo,
    toggleInsurance,
    setAmpera,
    setInsurance,
    setCombo,
    getCombo,
    getAddonsApplieds,
    getAddonsList,
    getAmperaValue,
  }
})
