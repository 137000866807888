<script setup lang="ts">
import IconInfo from '~icons/material-symbols/info-outline'
import IconOpenInNew from '~icons/material-symbols/open-in-new'

const props = defineProps<{
  eventMixpanel: string
}>()
const { track } = useMixpanel()
const { t } = useI18n()
const isLargeScreen = useMediaQuery('(min-width: 1024px)')

function redirectBNPL() {
  const isProduction = import.meta.env.MODE === 'production'

  track(`${props.eventMixpanel}`, { trigger: 'Clique no botão "Ver projeto"' })
  const urlProduction = 'https://sso.solfacil.com.br/realms/General/protocol/openid-connect/auth?response_type=code&client_id=bnpl-parcelado&scope=openid%20profile%20email&redirect_uri=https://parcelado.solfacil.com.br/Listagem'
  const urlStg = 'https://stg-sso.solfacil.com.br/realms/General/protocol/openid-connect/auth?response_type=code&client_id=bnpl-parcelado&scope=openid%20profile%20email&redirect_uri=https://parcelado.solfacil.com.br/Listagem'

  if (isProduction)
    window.open(urlProduction, '_blank')
  else
    window.open(urlStg, '_blank')
}
</script>

<template>
  <div class="flex items-center justify-between flex-wrap border-1 border-neutral-high-medium rounded-md my-2 px-4 md:system:px-6 py-2">
    <div>
      <div class="flex items-center gap-2 font-bold">
        {{ t('simulation.bnpl.banner.title') }}
        <SimulationTooltip
          :position="isLargeScreen ? 'right' : 'top'"
          :text="t('simulation.bnpl.banner.tooltip')"
          class="tooltip"
        >
          <IconInfo />
        </SimulationTooltip>
      </div>

      <div class="text-brand-primary-medium fonts-body-medium-regular opacity-90">
        {{ t('simulation.bnpl.banner.description') }}
      </div>
    </div>

    <SolButton id="btn-bnpl" variant="secondary" size="small" class="my-2" @click="redirectBNPL">
      {{ t('simulation.bnpl.banner.button') }}
      <template #icon:right>
        <IconOpenInNew />
      </template>
    </SolButton>
  </div>
</template>
