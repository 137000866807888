<script setup lang="ts">
import { useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import IMaterialSymbolsInfoOutline from '~icons/material-symbols/info-outline'
import IconLock from '~icons/material-symbols/lock'
import { useField, useForm } from 'vee-validate'
import { nextTick } from 'vue'
import * as zod from 'zod'
import { showMenuAccordingType } from '~/composables/uploadFile'
import CompanyRegisterService from '~/services/company-register/CompanyRegister'
import { useFinancing } from '~/store/useFinancing'
import { Formalization } from '~/types/enum'
import type { Document, DocumentModel, DocumentStatusEnum, TypesErrorsCompanyDocuments } from '~/utils/company-register/CompanyRegister'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import type { StageFormalization } from '~/utils/customer-register/CustomerRegister'
import download from '~/utils/download'
import type { FormalizationStatus } from '~/utils/financing-flow/Financing'

const props = defineProps<{
  position: number
  statusCompanyform: string
  stage: StageFormalization
  stageReproved: string
  statusProject: FormalizationStatus
}>()

const emit
  = defineEmits<{
    (e: 'errorsCompanyDocuments', v: TypesErrorsCompanyDocuments): void
    (e: 'clientStatusDocumentStep', v: keyof DocumentStatusEnum): void
  }>()

const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'application/pdf']
const { createErrorToast } = useToast()
const companyRegisterService = new CompanyRegisterService(useApi('customerRegister'))
const { track } = useMixpanel()
const router = useRouter()
const id = router.currentRoute.value.params.id as string
const blocked = ref(true)
const { financing } = useFinancing(id)
const disableStepsAccordingStatus = ['canceled', 'expired'].includes(financing?.status ?? '')
const EnumTags: DocumentStatusEnum = {
  pending: {
    id: 'clientDataPending',
    text: 'Em andamento',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  pendency: {
    id: 'clientDataPendency',
    text: 'Pendenciado',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  under_analysis: {
    id: 'clientDataUnderAnalysis',
    text: 'Em análise',
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  reproved: {
    id: 'clientDataReproved',
    text: 'Reprovado',
    variant: 'fill',
    size: 'medium',
    type: 'negative',
  },
  approved: {
    id: 'clientDataApproved',
    text: 'Aprovado',
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
}

onMounted(async () => {
  checkStageReproved()
  await getDocumentData()
})

const loadingDocument = ref(false)
const status = ref('' as FormalizationStatus['documentation'])
const responseDocument = ref({} as DocumentModel)
const fieldIsDisabledBasedStatus = ref(false)
const documentUrls = ref({
  identity: ref<string[]>([]),
  energy_bill: ref<string[]>([]),
  incorporation_document: ref<string[]>([]),
})

const tag = computed(() => EnumTags[props.statusProject.documentation])

const { t } = useI18n()
const statusIsPending = computed(() => status.value === 'pending')
const statusIsPendency = computed(() => status.value === 'pendency')
const initialValues = {
  uploadCompanyMajority: null,
  uploadCompanyLegalRepresentants: null,
  uploadCompanyDocument: null,
}

const validationSchema = computed(() => toTypedSchema(
  zod.object({
    uploadCompanyMajority: !hasFileOfType('identity') && !statusIsPending.value
      ? zod.any().optional()
      : zod.any().refine(uploadIdentifier => uploadIdentifier?.length, t('form.file_required')),
    uploadCompanyLegalRepresentants: !hasFileOfType('energy_bill') && !statusIsPending.value
      ? zod.any().optional()
      : zod.any().refine(uploadElectricityBill => uploadElectricityBill?.length, t('form.file_required')),
    uploadCompanyDocument: !hasFileOfType('incorporation_document') && !statusIsPending.value
      ? zod.any().optional()
      : zod.any().refine(uploadCompanyOfficializationDocuments => uploadCompanyOfficializationDocuments?.length, t('form.file_required')),
  }),
))

const { handleSubmit, validate, setFieldError } = useForm({
  validationSchema,
  initialValues,
})

const errorsValidation = ref(false)

onUpdated(async () => {
  updateStatusWithFlow()
})

function updateStatusWithFlow() {
  status.value = props.statusProject.documentation
}

async function getDocumentData() {
  loadingDocument.value = true

  try {
    const response = await companyRegisterService.get_documents_company(id)
    if (response) {
      responseDocument.value = response
      const { documents } = response
      status.value = props.statusProject.documentation
      const isApproved = status.value === 'approved'

      documents.forEach((document) => {
        const isIdentityDocument = document.type === 'identity'
        const isEnergyBillDocument = document.type === 'energy_bill'
        const isIncorporationDocument = document.type === 'incorporation_document'

        if ((isIdentityDocument && responseDocument.value.identity_matches_document) || (isIdentityDocument && isApproved)) {
          if (!documentUrls.value.identity.includes(document.url))
            documentUrls.value.identity.push(document.url)
        }
        else if ((isEnergyBillDocument && responseDocument.value.installation_address_matches_bill && responseDocument.value.installation_document_matches_bill) || (isEnergyBillDocument && isApproved)) {
          if (!documentUrls.value.energy_bill.includes(document.url))
            documentUrls.value.energy_bill.push(document.url)
        }
        else if ((isIncorporationDocument && responseDocument.value.incorporation_document_matches_document) || (isIncorporationDocument && isApproved)) {
          if (!documentUrls.value.incorporation_document.includes(document.url))
            documentUrls.value.incorporation_document.push(document.url)
        }
      })
    }

    emit('clientStatusDocumentStep', status.value)
    fieldIsDisabledBasedStatus.value = status.value === 'approved' || status.value === 'reproved' || status.value === 'under_analysis'

    if (status.value === 'pendency') {
      if (!response.identity_matches_document) {
        nextTick(() => {
          setFieldError('uploadCompanyMajority', t('electricityBill.errorMessageInstallationDocumentMatchesBill'))
          errorsValidation.value = true
        })
      }

      if (!response.installation_address_matches_bill) {
        nextTick(() => {
          setFieldError('uploadCompanyLegalRepresentants', t('formDocument.errorMessageInstallationAddressMatchesBill'))
          errorsValidation.value = true
        })
      }

      if (!response.incorporation_document_matches_document) {
        nextTick(() => {
          setFieldError('uploadCompanyDocument', t('electricityBill.errorMessageCompany'))
          errorsValidation.value = true
        })
      }

      emit('errorsCompanyDocuments', {
        identity_matches_document: response.identity_matches_document,
        incorporation_document_matches_document: response.incorporation_document_matches_document,
        installation_address_matches_bill: response.installation_address_matches_bill,
        installation_document_matches_bill: response.installation_document_matches_bill,
        legal_representant_missing: response.legal_representant_missing,
      } as TypesErrorsCompanyDocuments)

      errorsValidation.value = !response.identity_matches_document
      || !response.installation_address_matches_bill
      || !response.incorporation_document_matches_document
      || !response.installation_document_matches_bill
      || response.legal_representant_missing

      return
    }
    errorsValidation.value = false
  }
  catch {
    loadingDocument.value = false
  }
  finally {
    loadingDocument.value = false
  }
}

const loadingUpload = ref(false)

async function uploadFile(uploadCompanyMajority: File[], uploadCompanyLegalRepresentants: File[], uploadIncorporationDocument: File[]) {
  const uploadPromises: Promise<ResponseModel<Document>>[] = []

  if (uploadCompanyMajority) {
    uploadCompanyMajority.forEach((file: File) => {
      const formDataIdentity = new FormData()
      formDataIdentity.append('document', file, file.name)
      uploadPromises.push(companyRegisterService.upload_documents(id, 'identity', formDataIdentity))
    })
  }

  if (uploadCompanyLegalRepresentants) {
    uploadCompanyLegalRepresentants.forEach((file: File) => {
      const formDataEnergyBill = new FormData()
      formDataEnergyBill.append('document', file, file.name)
      uploadPromises.push(companyRegisterService.upload_documents(id, 'energy_bill', formDataEnergyBill))
    })
  }

  if (uploadIncorporationDocument) {
    uploadIncorporationDocument.forEach((file: File) => {
      const formDataIncorporationDocument = new FormData()
      formDataIncorporationDocument.append('document', file, file.name)
      uploadPromises.push(companyRegisterService.upload_documents(id, 'incorporation_document', formDataIncorporationDocument))
    })
  }

  await Promise.all(uploadPromises)
}

const saveDocument = handleSubmit(async (event: any) => {
  loadingUpload.value = true
  const { valid } = await validate()
  if (valid) {
    try {
      await uploadFile(event.uploadCompanyMajority, event.uploadCompanyLegalRepresentants, event.uploadCompanyDocument)
      await getDocumentData()
      await companyRegisterService.confirm_documents(id)

      track('formalizing_documentation_button_next', { trigger: 'Clique no botão enviar em Documentação PJ' })
      fieldIsDisabledBasedStatus.value = true
    }
    catch {
      loadingUpload.value = false
      createErrorToast(t('formDocument.responseError'))
    }
    finally {
      loadingUpload.value = false
    }
  }
})

function hasFileOfType(type: string) {
  return !responseDocument.value?.documents?.some((document: any) => document.type === type)
}

const disabledFieldsAndButton = computed(() => disableStepsAccordingStatus || props.statusProject.registration === 'pending')
const showAlertMessageAwaitingCustomerData = computed(() => props.statusProject.registration === 'pending')
const showAlertReason = computed(() => {
  return statusIsPendency.value
    && !['', null].includes(responseDocument.value.reason)
    && props.statusProject.registration !== 'pending'
})

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.DOCUMENTATION
}

const disabledSaveButton = computed(() => disableStepsAccordingStatus || ((fieldIsDisabledBasedStatus.value || showAlertMessageAwaitingCustomerData.value) && !errorsValidation.value))

function clearDocumentUrls() {
  documentUrls.value.identity = []
  documentUrls.value.energy_bill = []
  documentUrls.value.incorporation_document = []
}

async function updateAfterAnalysisCanceled() {
  clearDocumentUrls()
  fieldIsDisabledBasedStatus.value = false
  status.value = 'pending'
}

const showModalToPreviewFile = ref(false)
const imgUrl = ref('')

function previewFile(url: string) {
  imgUrl.value = url
  showModalToPreviewFile.value = true
}
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.DOCUMENTATION}`"
    :title="t('customer.document_data_title')"
    :position="String(position)"
    :status="props.statusProject.documentation"
    :tag="blocked ? undefined : tag"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.DOCUMENTATION"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <template v-if="!loadingDocument">
      <div class="mt-2">
        <p>{{ t('customer.document_data_subtitle') }}</p>
      </div>
      <div class="form-client-data">
        <SolAlert
          v-if="showAlertMessageAwaitingCustomerData"
          id="informative-feedback-receipt-model"
          class="mb-6"
          :title="t('formDocument.messageTitleAwaitingCustomerData')"
          feedback="warning"
        >
          {{ t('formDocument.messageTextAwaitingCustomerData') }}
        </SolAlert>

        <SolAlert
          v-if="showAlertReason && responseDocument.documents?.length > 0"
          id="informative-feedback-receipt-model"
          class="my-3"
          :title="t('formDocument.alertError')"
          feedback="error"
        >
          {{ responseDocument.reason }}
        </SolAlert>
        <form>
          <SolFileUpload
            v-if="!documentUrls.identity.length"
            id="uploadCompanyMajority"
            name="uploadCompanyMajority"
            class="mt-8"
            :class="{ 'pointer-events-none opacity-60': loadingUpload || disabledFieldsAndButton }"
            :use-field="useField"
            :accept="ACCEPTED_FILE_TYPES.join(',')"
            :multiple="true"
            :placeholder="t('formDocument.placeholder')"
            :label="t('company_form_document.label_document_majority_and_legal_representants')"
            :disabled="disabledFieldsAndButton"
          />
          <SolFileUpload
            v-for="(url, index) in documentUrls.identity"
            id="uploadCompanyMajority"
            :key="index"
            name="uploadCompanyMajority"
            :class="[{ 'pointer-events-none opacity-60': loadingUpload || disabledFieldsAndButton }, { 'mt-8': index === 0 }]"
            :use-field="useField"
            :label="index === 0 ? t('company_form_document.label_document_majority_and_legal_representants') : ''"
            :disabled="disabledFieldsAndButton"
            :download-src="url"
            :download-menu="showMenuAccordingType(url) ? ['download', 'preview'] : ['download']"
            @menu:download="download.downloadFile(documentUrls.identity[index])"
            @menu:preview="previewFile(url)"
          />

          <SolFileUpload
            v-if="!documentUrls.energy_bill.length"
            id="uploadCompanyLegalRepresentants"
            name="uploadCompanyLegalRepresentants"
            class="mt-8"
            :class="{ 'pointer-events-none opacity-60': loadingUpload || disabledFieldsAndButton }"
            :use-field="useField"
            :accept="ACCEPTED_FILE_TYPES.join(',')"
            :multiple="true"
            :placeholder="t('formDocument.placeholder')"
            :label="t('company_form_document.label_energy_bill')"
            :disabled="disabledFieldsAndButton"
          />
          <SolFileUpload
            v-for="(url, index) in documentUrls.energy_bill"
            id="uploadCompanyLegalRepresentants"
            :key="index"
            name="uploadCompanyLegalRepresentants"
            :class="[{ 'pointer-events-none opacity-60': loadingUpload || disabledFieldsAndButton }, { 'mt-8': index === 0 }]"
            :use-field="useField"
            :label="index === 0 ? t('company_form_document.label_energy_bill') : ''"
            :disabled="disabledFieldsAndButton"
            :download-src="url"
            :download-menu="showMenuAccordingType(url) ? ['download', 'preview'] : ['download']"
            @menu:download="download.downloadFile(documentUrls.energy_bill[index])"
            @menu:preview="previewFile(url)"
          />

          <div class="mt-xs">
            <div class="mb-quark flex items-center mb-0" :class="{ 'pointer-events-none opacity-60': loadingUpload || disabledFieldsAndButton }">
              <strong class="label text-brand-primary-pure text-[14px]">
                {{ t('company_form_document.label_document_company') }}
              </strong>
              <SimulationTooltip
                position="right"
                :text="t('company_form_document.tooltip_accepted_documents')"
              >
                <IMaterialSymbolsInfoOutline class="ml-1.5" />
              </SimulationTooltip>
            </div>
            <SolFileUpload
              v-if="!documentUrls.incorporation_document.length"
              id="uploadCompanyDocument"
              name="uploadCompanyDocument"
              :class="{ 'pointer-events-none opacity-60': loadingUpload || disabledFieldsAndButton }"
              :use-field="useField"
              :accept="ACCEPTED_FILE_TYPES.join(',')"
              :multiple="true"
              :placeholder="t('formDocument.placeholder')"
              label=""
              :disabled="disabledFieldsAndButton"
            />
            <SolFileUpload
              v-for="(url, index) in documentUrls.incorporation_document"
              id="uploadCompanyDocument"
              :key="index"
              name="uploadCompanyDocument"
              :class="{ 'pointer-events-none opacity-60': loadingUpload || disabledFieldsAndButton }"
              :use-field="useField"
              label=""
              :disabled="disabledFieldsAndButton"
              :download-src="url"
              :download-menu="showMenuAccordingType(url) ? ['download', 'preview'] : ['download']"
              @menu:download="download.downloadFile(documentUrls.incorporation_document[index])"
              @menu:preview="previewFile(url)"
            />
          </div>

          <div class="flex justify-end border-t border-neutral-high-medium pt-6 mt-8 gap-4 flex-col md:system:flex-row">
            <SharedCancelStep
              :status-step="statusProject.documentation"
              step-to-cancel="document_form"
              :disabled-button-by-status="disableStepsAccordingStatus"
              @step-canceled="updateAfterAnalysisCanceled()"
            />
            <SolButton
              id="document_confirm"
              class="w-full md:system:w-auto order-1 md:system:order-2"
              :loading="loadingUpload"
              size="large"
              :disabled="disabledSaveButton"
              @click="saveDocument"
            >
              {{ t('app.send') }}
            </SolButton>
          </div>
        </form>
      </div>
    </template>
    <template v-else>
      <div class="container">
        <div class="my-4 card-container flex flex-col justify-between w-full">
          <span class="loader-project" />
        </div>
      </div>
    </template>
  </CustomerElementAccordion>
  <SolModal
    id="modal"
    :is-open="showModalToPreviewFile"
    title=""
    :size="{ desktop: 'extra-large', mobile: 'full' }"
    @close="showModalToPreviewFile = false"
  >
    <div class="flex justify-center w-full">
      <img :src="imgUrl" alt="Document img">
    </div>
  </SolModal>
</template>

<style lang="scss" scoped>
.error-message {
  @apply text-feedback-negative-pure py-nano;
  @apply fonts-subtitle-small;
}

.form-client-data {
  @apply mt-6 mb-0;
}
</style>
