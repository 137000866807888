import { FetchError } from 'ohmyfetch'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import type { ReceiptModel, ReceiptModelSupplier } from '~/utils/receipt-model/ReceiptModel'
import BaseService from '../BaseService'

export default class ReceiptModelService extends BaseService {
  async get_receipt_model(projectId: string): Promise<ReceiptModel | { equipment_not_found: boolean }> {
    try {
      const response = await super.api<ReceiptModel>(`/receipt-model/${projectId}`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      if (e instanceof FetchError) {
        const response = e.data
        const errors = response.detail
        if (errors.error_message === 'equipement_not_found') {
          return {
            equipment_not_found: true,
          }
        }
      }
      return Promise.reject(e)
    }
  }

  async get_receipt_model_suppliers(): Promise<ReceiptModelSupplier[]> {
    try {
      const response = await super.api<ReceiptModelSupplier[]>('/receipt-model/suppliers', {
        method: 'GET',
      })

      return response
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async post_receipt_model(body: any): Promise<ResponseModel<any>> {
    try {
      const response = await super.api<ResponseModel<any>>('/receipt-model', {
        method: 'POST',
        body: {
          ...body,
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async post_receipt_model_upload(body: any): Promise<ResponseModel<any>> {
    try {
      const response = await super.api<ResponseModel<any>>('/receipt-model/upload', {
        method: 'POST',
        body,
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async delete_receipt_model_upload(id: string): Promise<ResponseModel<any>> {
    try {
      const response = await super.api<ResponseModel<any>>(`/receipt-model/upload/${id}`, {
        method: 'DELETE',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  /**
   * Get invoices in waiting analysis
   */
  async get_receipt_model_invoices(id: string): Promise<ResponseModel<any>> {
    try {
      const response = await super.api<ResponseModel<any>>(`/receipt-model/invoices/${id}`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  /**
   * Set the receipt model in analysis
   */
  async post_receipt_model_in_analysis(body: { project_id: string, in_analysis: boolean }): Promise<ResponseModel<any>> {
    try {
      const response = await super.api<ResponseModel<any>>('/receipt-model/in-analysis', {
        method: 'POST',
        body: {
          ...body,
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(e)
    }
  }
}
