import type { PersonService } from './person-api.types'

export function getCurrentScore(user: PersonService.User) {
  if (!user.partner)
    return '-'

  if (user.partner?.sfplus?.is_level_black && user.partner.sfplus.black_status)
    return user.partner.sfplus.black_status.kw_reached_month

  return user.partner?.sfplus?.score?.current || '-'
}

export function getGoalScore(user: PersonService.User) {
  if (!user.partner || !user?.partner?.sfplus) {
    return '-'
  }

  if (user?.partner?.sfplus?.is_level_black && user.partner.sfplus.black_status) {
    return user.partner.sfplus.black_status.kw_goal_month
  }

  return user.partner.sfplus.score?.goal || '-'
}

export function getHasRisk(user: PersonService.User) {
  return user.partner?.sfplus?.downgrade?.has_risk || false
}

export function getNextTrasitionDate(user: PersonService.User) {
  const nextTransitionDate = user?.partner?.sfplus?.next_transition_date

  if (!nextTransitionDate) {
    return '-'
  }

  return nextTransitionDate
}

export function getLevel(user: PersonService.User) {
  return user.partner?.sfplus?.is_level_black
    ? 'Black'
    : (user.partner?.sfplus?.level || '-')
}
