import { useToast } from '@solfacil/girassol'
import { Thunder } from './__generated__/zeus'
import http from './financial-bff.http'

const { createErrorToast } = useToast()

const thunder = Thunder(async (query, variables) => {
  const res = await http.post('/graphql', {
    query,
    variables,
  })

  if (res.data.errors) {
    createErrorToast('Não conseguimos carregar os financiamentos.')
    res.data.errors.forEach((error: any) => console.error(error))
    return {
      financings: {
        data: [],
      },
    }
  }

  return res.data.data
})

export default thunder
