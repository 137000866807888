import type { SimulatorService } from './simulator.types'
import { ofetch } from 'ofetch'
import cookies from '~/utils/cookies'

const http = ofetch.create({
  baseURL: import.meta.env.VITE_SIMULATOR_SERVICE_URL,
  headers: {
    Authorization: `Bearer ${cookies.read('access_token')}`,
  },
})

export default {
  /**
   * Get project details with the given id
   */
  project(id: string) {
    return http<SimulatorService.ProjectResponse>(`/project/${id}`)
  },

  /**
   * Get all projects
   */
  projects(reqParams: SimulatorService.ProjectsRequest) {
    const path = new URLSearchParams()
    path.set('page', `${reqParams.page || 0}`)
    path.set('offset', `${reqParams.size || 10}`) // it's actually the page size
    path.set('order', reqParams.order || 'desc')

    return http<SimulatorService.ProjectsResponse>(`/project/list/all?${path.toString()}`)
  },
}
