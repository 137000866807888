import type { FinancingFlowResponse, FinancingList, FinancingListItem, FinancingListParams } from '~/utils/financing-flow/Financing'
import BaseService from '../BaseService'

const DEFAULT_FINANCING_LIST_PARAMS: FinancingListParams = {
  page: 1,
  page_size: 10,
  order_by: 'created_at:desc',
}

export default class FinancingFlowService extends BaseService {
  async getFinancingList(params?: FinancingListParams): Promise<FinancingList> {
    const _params = { ...DEFAULT_FINANCING_LIST_PARAMS, ...params }

    let url = '/'

    if (Array.isArray(_params.steps)) {
      url += `?${_params.steps.map(step => `steps=${step}`).join('&')}`
      delete _params.steps
    }

    const response = await super.api<FinancingList>(url, {
      method: 'GET',
      params: _params,
    })

    return response
  }

  async getFinancingFlow(id: string): Promise<FinancingFlowResponse> {
    try {
      const response = await super.api<FinancingFlowResponse>(`/financing/by-project-id/${id}`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(new Error(String(e)))
    }
  }

  async getFinancingByProjectId(projectId: string): Promise<FinancingListItem> {
    const response = await super.api<FinancingListItem>(`/financing/by-project-id/${projectId}`, {
      method: 'GET',
    })

    return response
  }
}
