export default {
  clear(): void {
    localStorage.clear()
  },
  get<T = number | string | unknown>(key: string): null | T {
    const serializedValue = localStorage.getItem(key)
    if (!serializedValue)
      return null

    return JSON.parse(serializedValue)
  },

  get length(): number {
    return localStorage.length
  },

  remove(key: string): void {
    localStorage.removeItem(key)
  },

  set<T = number | string | unknown>(key: string, value: T): void {
    const serializedValue = JSON.stringify(value)
    localStorage.setItem(key, serializedValue)
  },
}
