<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useMixpanel()

const srcImg = {
  logo: '/images/combo_logo.svg',
  animation: '/images/combo_percent.svg',
}

function goCombo() {
  track('financing-list_banner_saiba-mais', { trigger: 'Clique no botão de saiba mais que tem no banner referente ao Combo Fácil na listagem de financiamentos' })
  const url = 'https://combofacil.solfacil.com.br/'
  window.open(url, '_blank')
}
</script>

<template>
  <section class="banner">
    <div class="flex justify-between align-baseline md:site:align-middle">
      <img :src="srcImg.logo" class="w-33" title="Logo" alt="Logo">
      <img :src="srcImg.animation" title="Imagen solfácil" alt="Solfácil">
    </div>
    <div class="flex flex-col self-center mt-micro">
      <h1 class="fonts-heading-h4 md:site:fonts-heading-h3 text-neutral-high-pure">
        {{ t('financing.banner.title') }}
      </h1>
      <p class="fonts-body-small-regular my-quark md:site:fonts-body-large-regular text-neutral-high-pure">
        {{ t('financing.banner.subtitle') }}
      </p>
      <SolButton id="secondary-large" variant="secondary" on-color class="link-banner" size="small" @click="goCombo">
        <span class="text-neutral-high-pure justify-end">
          {{ t('financing.button.know_more') }}
        </span>
      </SolButton>
    </div>
  </section>
</template>

<style scoped lang="scss">
.banner {
  @apply h-auto w-full rounded-md mb-2xs px-nano py-nano flex justify-between flex-wrap;
  background: linear-gradient(90deg, #BFFF00 -105.99%, #42CF45 -65.65%, #008859 134.97%);

  @screen md:system {
    @apply mb-xs px-sm py-0;
  }

  .link-banner {
    @apply self-end;
    @apply font-bold;
    @apply mb-4xs mr-4xs;

    @screen md:system {
      @apply m-nano;
    }
  }

  >div {
    >img:nth-child(2) {
      @apply mx-xs w-92px;

      @screen md:system {
        @apply mx-md w-auto;
      }
    }
  }
}
</style>
