import * as LDClient from 'launchdarkly-js-client-sdk'

const context = {
  kind: 'user',
  key: 'pr.sim.financial-products-simulator',
}

export async function useFlag<T>(flag: string, params?: { [key: string]: string | number }): Promise<boolean | T> {
  try {
    const client = await initializeLdk(params)

    if (!client)
      return false

    const boolFlagValue = client.variation(flag, false) as boolean
    return boolFlagValue as boolean
  }
  catch {
    return false
  }
}

async function initializeLdk(params?: { [key: string]: string | number }) {
  const { rest } = useConnections()
  const client = LDClient.initialize(rest.ldk.key, { ...context, ...params })
  try {
    await client.waitForInitialization()
    return client
  }
  catch (error) {
    console.error(error)
  }
}
