<script setup lang="ts">
import type { SimulatorV3 } from '~/services/simulator-v3/simulator.types.ts'
import currencyUtils from '~/utils/currency'
import numbersUtils from '~/utils/numbers'

defineProps<{
  data: SimulatorV3.BNPLSummary | null
  loading: boolean
}>()

const { t } = useI18n()
</script>

<template>
  <!-- loading state -->
  <dic v-if="loading">
    <div class="rounded-lg bg-neutral-high-pure min-h-40 flex items-center justify-center">
      <Loading />
    </div>
  </dic>

  <div v-else-if="!loading && data" class="w-full rounded-lg bg-neutral-high-pure p-4 md:system:p-8">
    <div class="items-center hidden md:system:flex">
      <div class="fonts-body-x-large-bold">
        {{ t('simulation.bnpl.id.summary.title.summary') }}
      </div>
      <div class="flex text-neutral-low-light">
        <div class="mx-2">
          |
        </div>
        <div>{{ t('simulation.bnpl.id.summary.title.service') }}</div>
      </div>
    </div>

    <div class="mt-4">
      <div class="fonts-body-large-bold text-neutral-low-dark">
        {{ t('simulation.bnpl.id.summary.installment.title') }}
      </div>
      <div class="flex flex-col items-center gap-1 bg-neutral-high-light rounded-lg py-4 my-2">
        <div class="flex gap-2 fonts-body-small-regular text-neutral-low-light">
          <div>{{ t('simulation.bnpl.id.summary.installment.prohibited') }}</div>
          <div>{{ currencyUtils.formatBRL(data.installment.prohibited) }}</div>
          +
        </div>
        <div class="flex items-end gap-2 text-neutral-low-dark">
          <div class="fonts-body-small-regular mb-1">
            {{ data.installment.installments }}x
          </div>
          <div class="fonts-heading-h3">
            {{ currencyUtils.formatBRL(data.installment.value) }}
          </div>
        </div>
      </div>

      <div class="flex justify-between fonts-body-medium-regular">
        <div class="text-neutral-low-light">
          {{ t('simulation.bnpl.id.summary.installment.cet') }}
        </div>
        <div class="text-neutral-low-dark">
          {{ numbersUtils.percentage(data.installment.percentage) }}% {{ t('simulation.bnpl.id.summary.installment.am') }}
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div>
        <div class="fonts-body-large-bold text-neutral-low-dark mb-2">
          {{ t('simulation.bnpl.id.summary.enterprise.title') }}
        </div>

        <div class="text-neutral-low-light">
          <div class="flex justify-between">
            <div>{{ t('simulation.bnpl.id.summary.enterprise.total') }}</div>
            <div class="text-neutral-low-dark">
              {{ currencyUtils.formatBRL(data.enterprise.total) }}
            </div>
          </div>

          <div class="ml-3">
            <div class="flex justify-between">
              <div>{{ t('simulation.bnpl.id.summary.enterprise.kit') }}</div>
              <div>{{ currencyUtils.formatBRL(data.enterprise.kit) }}</div>
            </div>
            <div class="flex justify-between">
              <div>{{ t('simulation.bnpl.id.summary.enterprise.tax') }}</div>
              <div>{{ currencyUtils.formatBRL(data.enterprise.tax) }}</div>
            </div>
            <div class="flex justify-between">
              <div>{{ t('simulation.bnpl.id.summary.enterprise.value') }}</div>
              <div class="text-neutral-low-dark fonts-body-large-bold">
                {{ currencyUtils.formatBRL(data.enterprise.value) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="fonts-body-large-bold text-neutral-low-dark mb-2">
          {{ t('simulation.bnpl.id.summary.client.title') }}
        </div>

        <div class="text-neutral-low-light">
          <div class="flex justify-between">
            <div>{{ t('simulation.bnpl.id.summary.client.total') }}</div>
            <div class="text-neutral-low-dark">
              {{ currencyUtils.formatBRL(data.client.total) }}
            </div>
          </div>

          <div class="ml-3">
            <div class="flex justify-between">
              <div>{{ t('simulation.bnpl.id.summary.client.prohibited') }}</div>
              <div class="text-neutral-low-dark">
                {{ currencyUtils.formatBRL(data.client.prohibited) }}
              </div>
            </div>
            <div class="flex justify-between">
              <div>{{ t('simulation.bnpl.id.summary.client.installments') }}</div>
              <div class="text-neutral-low-dark">
                {{ data.client.installments }}x {{ t('simulation.bnpl.id.summary.client.of') }} {{ currencyUtils.formatBRL(data.client.installment) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
