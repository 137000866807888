import type { PersonService } from './person-api.types'
import http from './person-api.http'

export async function getUser(userId: number | string) {
  const res = await http.get<PersonService.GetWhoAmIResponse>(`/user/${userId}`)
  return res.data
}

export default {
  getUser,
}
