<script setup lang="ts">
import { SolSidebar } from '@solfacil/girassol'

import IconMoney from '~icons/girassol/attach-money'
import IconDashboard from '~icons/girassol/dashboard'
import IconPayments from '~icons/girassol/payments'

import PaymentBFFService from '~/services/paymentBFF/PaymentBFF'
import type { Item, Product } from '~/utils/navigation/Sidebar'

const props = defineProps<{
  isOpenSidebar: boolean
}>()

const router = useRouter()
const openSidebar = ref(props.isOpenSidebar)
const activeMenu = (routeMenu: string) => router.currentRoute.value.name === routeMenu

const product = ref({
  id: 'financing',
  icon: IconMoney,
  title: 'Financiamento',
  action: () => window.location.href = '/',
}) as Ref<Product>

const items = ref([
  {
    icon: IconDashboard,
    name: 'Início',
    isActive: activeMenu('index'),
    action: () => window.location.href = '/',
  },
]) as Ref<Item[]>

watch(() => router.currentRoute.value.name, () => {
  items.value.forEach((item) => {
    item.isActive = activeMenu(item.name)
  })
})

async function checkPartnerAccount() {
  const paymentBFFService = new PaymentBFFService(useApi('paymentBFF'))
  const hasAccount = await paymentBFFService.hasAccountManagement()

  if (hasAccount) {
    items.value.push({
      icon: IconPayments,
      name: 'Extrato Solfácil',
      isActive: activeMenu('bank-statement'),
      action: () => window.location.href = '/bank-statement',
    })
  }
}

watch(() => props.isOpenSidebar, () => openSidebar.value = props.isOpenSidebar)

onMounted(() => {
  checkPartnerAccount()
})
</script>

<template>
  <div>
    <SolSidebar
      :items="items"
      :product="product"
      :is-open-mobile-sidebar="openSidebar"
      @click-menu="openSidebar = !openSidebar"
    />
  </div>
</template>
