import type { AvailableConnection } from '~/types'

export function useEnvironment(): AvailableConnection {
  if (import.meta.env.MODE === 'development')
    return 'dev'

  if (import.meta.env.MODE === 'staging')
    return 'stg'

  return 'prod'
}
