import { FetchError } from 'ohmyfetch'
import type { BankStatementResponse } from '~/types'
import BaseService from '../BaseService'
import { AuthorizationError } from './AuthorizationError'

interface BankStatementParams {
  sort: 'RECENT' | 'OLDEST'
  status: 'IN_PROGRESS' | 'COMPLETED'
  customer_document?: string
  date_range?: string
}

export default class PaymentBFFService extends BaseService {
  async getBankStatement(params: BankStatementParams): Promise<BankStatementResponse> {
    try {
      const _params = Object.fromEntries(Object.entries(params).filter(([, value]) => Boolean(value)))

      const response = await super.api<BankStatementResponse>('/v1/transfer/bank-statement', {
        method: 'GET',
        query: _params,
      })

      return response
    }
    catch (error) {
      if (error instanceof FetchError && error.statusCode === 404)
        return { filters: { customer_document: null }, results: [] }

      if (error instanceof FetchError && error.statusCode === 403)
        throw new AuthorizationError('You are not allowed to access this resource', error.data.code, error)

      throw error
    }
  }

  async downloadBankStatement(id: string): Promise<Blob> {
    const response = await super.api(`/v1/transfer/${id}/transfer-receipt`, {
      method: 'GET',
      responseType: 'blob',
    })

    return response
  }

  async hasAccountManagement(): Promise<boolean> {
    try {
      const response = await super.api('/v1/account-management/check', { method: 'GET' })

      return response.hasAccount
    }
    catch (error) {
      console.error(error)
      return false
    }
  }
}
